import { ref } from 'vue'
import { defineStore } from 'pinia'

import { IconName } from '@/shared/ui/IconBase'

export type ToasterParams = {
    type?: 'default' | 'critical'
    icon?: Maybe<IconName>
    isIconLeft?: boolean
    title: string
}

export const useToasterSettings = defineStore('ToasterStore', () => {
    const list = ref<
        (ToasterParams & {
            id: number
        })[]
    >([])
    const timeouts = ref<Record<number | string, NodeJS.Timeout>>({})

    function pauseTimeouts() {
        Object.keys(timeouts.value).forEach(key => {
            clearTimeout(timeouts.value[key])
        })
    }

    function restartTimeouts() {
        Object.keys(timeouts.value).forEach((id, index) => {
            timeouts.value[id] = setTimeout(() => {
                remove(+id)
                clearTimeout(timeouts.value[id])
            }, 4000 + index * 300)
        })
    }

    function remove(id: number) {
        list.value = list.value.filter(item => item.id !== id)
    }

    function show(params: ToasterParams | string) {
        let newToaster: ToasterParams & {
            id: number
        }
        const id = list.value[0] ? list.value[0].id + 1 : 1
        // only for test
        // const TestTitle = 'Скопировано'
        // const icon: IconName | undefined =
        //     id % 3 !== 0 ? 'icon-plus_outline' : undefined
        // const isIconLeft = id % 2 === 0

        if (typeof params === 'string') {
            newToaster = {
                id,
                type: 'default',
                title: params,
            }
        } else {
            newToaster = {
                ...params,
                id,
                type: params.type ?? 'default',
            }
        }

        if (list.value.length === 3) {
            list.value.pop()
        }

        list.value.unshift(newToaster)
        timeouts.value[id] = setTimeout(() => {
            remove(newToaster.id)
            clearTimeout(timeouts.value[id])
        }, 5000)
    }

    function showError(text: string, icon: IconName = 'icon-alert_outline') {
        show({
            type: 'critical',
            title: text,
            icon,
        })
    }

    return {
        list,

        show,
        showError,
        pauseTimeouts,
        restartTimeouts,
        remove,
    }
})

export const useToaster = () => {
    const store = useToasterSettings()

    return {
        show: store.show,
        showError: store.showError,
    }
}
