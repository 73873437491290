// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GAcRp-ButtonIcon-module_root{width:2.3333333333rem;height:2.3333333333rem;border-radius:50%;position:relative;cursor:pointer;transition:background-color .3s ease}.GAcRp-ButtonIcon-module_root .F441c-ButtonIcon-module_icon{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}.C_HYK-ButtonIcon-module_view_inverse{background-color:hsla(0,0%,100%,.25)}.C_HYK-ButtonIcon-module_view_inverse .F441c-ButtonIcon-module_icon{color:#fff}@media(hover:hover),(min-width:1200px){.C_HYK-ButtonIcon-module_view_inverse:hover{background-color:hsla(0,0%,100%,.15)}}.hFl57-ButtonIcon-module_view_critical-flat .F441c-ButtonIcon-module_icon{color:#ff2b00}@media(hover:hover),(min-width:1200px){.hFl57-ButtonIcon-module_view_critical-flat:hover .F441c-ButtonIcon-module_icon{color:#c20}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "GAcRp-ButtonIcon-module_root",
	"icon": "F441c-ButtonIcon-module_icon",
	"view_inverse": "C_HYK-ButtonIcon-module_view_inverse",
	"view_critical-flat": "hFl57-ButtonIcon-module_view_critical-flat"
};
export default ___CSS_LOADER_EXPORT___;
