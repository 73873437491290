<template lang="pug">
button-component(
    v-bind="buttonProps"
    :isLoading="isLoading"
    @click="onClick"
)
    slot
    
</template>

<script lang="ts">
import Vue, { computed, PropType, ref } from 'vue'

import * as MyObject from '@/packages/utils/object'

import { Button, buttonProps, ButtonProps } from '@/shared/ui/Button'

export type ButtonActionProps = ButtonProps & {
    action:
        | ((...args: unknown[]) => Promise<unknown>)
        | ((...args: unknown[]) => void)
}

export type ButtonActionEmits = {
    (event: 'reject'): void
    (event: 'resolve'): void
}

export default Vue.extend({
    name: 'ButtonAction',
    props: {
        ...buttonProps,
        action: Function as PropType<ButtonActionProps['action']>,
    },
    setup(props, { emit }: { emit: ButtonActionEmits }) {
        const isLoading = ref(false)

        async function onClick() {
            try {
                isLoading.value = true
                await props.action()
                emit('resolve')
            } catch (e) {
                emit('reject')
            } finally {
                isLoading.value = false
            }
        }

        const buttonProps = computed(() => {
            return MyObject.omit(props, ['action'])
        })

        return {
            isLoading,
            onClick,
            buttonProps,
        }
    },
    components: {
        'button-component': Button,
    },
})
</script>
