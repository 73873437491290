// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ac6XA-Loader-module_root{position:relative;border-radius:100%;overflow:hidden}.ac6XA-Loader-module_root img,.ac6XA-Loader-module_root svg{display:block;width:100%;height:100%}.ac6XA-Loader-module_root svg{animation:FXIGp-Loader-module_rotate360 1s linear infinite}.iGBBN-Loader-module_size_l{width:1.6666666667rem;height:1.6666666667rem}.WGD9Z-Loader-module_size_xxl{width:6.6666666667rem;height:6.6666666667rem}@keyframes FXIGp-Loader-module_rotate360{0%{transform:rotate(0)}to{transform:rotate(-1turn)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ac6XA-Loader-module_root",
	"rotate360": "FXIGp-Loader-module_rotate360",
	"size_l": "iGBBN-Loader-module_size_l",
	"size_xxl": "WGD9Z-Loader-module_size_xxl"
};
export default ___CSS_LOADER_EXPORT___;
