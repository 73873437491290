import Vue from 'vue'
import 'lazysizes'
import 'lazysizes/plugins/blur-up/ls.blur-up'
import '@/shared/config'
import 'whatwg-fetch'

import { pinia } from '@/app/pinia'

import lazysizes from '@/shared/lib/directives/lazysizes'

import App from './app/App.vue'

Vue.directive('lazysizes', lazysizes)

Vue.config.productionTip = false

const app = new Vue({
    pinia,
    render: h => h(App),
})

app.$mount('#app')
