<template lang="pug">
div(
    :class="classes"
    v-on="listeners"
)
    text-component(
        :class="styles.label"
        color="inverse-secondary"
        name="captionRegular"
    ) {{ label }}
    div(
        :class="styles.content"
    )
        text-component(
            :class="styles.title"
            color="inverse"
            name="headlineMedium"
            :isAnimated="false"
            :title="title"
        ) {{ title }}
        button-icon-component(
            v-if="isCopy"
            :class="styles.button"
            icon="icon-copy"
        )
</template>

<script lang="ts">
import Vue, { computed, PropType, useCssModule } from 'vue'

import { ButtonIcon } from '@/shared/ui/ButtonIcon'
import { TheText } from '@/shared/ui/TheText'
import { useCopyText } from '@/shared/lib/hooks/useCopyText'

export type AccountDetailProps = {
    label: string
    title: string | number
    isCopy?: boolean
}

export default Vue.extend({
    name: 'AccountDetail',
    props: {
        label: {
            type: String as PropType<AccountDetailProps['label']>,
            required: true,
        },
        title: {
            type: [String, Number] as PropType<AccountDetailProps['title']>,
            required: true,
        },
        isCopy: {
            type: Boolean as PropType<AccountDetailProps['isCopy']>,
            default: true,
        },
    },
    setup(props) {
        const styles = useCssModule()
        const toaster = useCopyText()
        function copy() {
            toaster.copy(props.title.toString())
        }

        const classes = computed(() => {
            return {
                [styles['is-clickable']]: Boolean(listeners.value),
            }
        })

        const listeners = computed(() => {
            if (!props.isCopy) {
                return null
            }

            return {
                click: copy,
            }
        })

        return {
            styles,
            classes,
            listeners,
        }
    },
    components: {
        'button-icon-component': ButtonIcon,
        'text-component': TheText,
    },
})
</script>

<style module lang="scss" src="./AccountDetail.module.scss" />
