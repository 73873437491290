<template lang="pug">
transition-fade-component
    component( 
        :is="tag"
        :data-testid="testId"
        :class="classes"
        :key="key"
    ) 
        slot
</template>

<script lang="ts">
import Vue, { computed, PropType, toRefs, useCssModule } from 'vue'

import { useLanguage } from '@/entities/order/model'

import { propsDefault } from '@/shared/lib/vue-utils/propsDefault'
import { fonts, colors, aligns } from '@/shared/ui/TheText/data'
import { TransitionFade } from '@/shared/ui/transitions/TransitionFade'

export type FontName = (typeof fonts)[number]

export type TextProps = PropsDefault<{
    tag?: 'span' | 'p' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    color?: (typeof colors)[number]
    align?: (typeof aligns)[number]
    name?: FontName
    isAnimated?: boolean
}>

export default Vue.extend({
    name: 'TheText',
    props: {
        tag: {
            type: String as PropType<TextProps['tag']>,
            default: 'p',
        },
        color: {
            type: String as PropType<TextProps['color']>,
            default: 'primary',
        },
        align: {
            type: String as PropType<TextProps['align']>,
        },
        name: {
            type: String as PropType<TextProps['name']>,
            default: 'captionRegular',
        },
        isAnimated: {
            type: Boolean as PropType<TextProps['isAnimated']>,
            default: true,
        },
        ...propsDefault,
    },
    setup(props) {
        const { name, color } = toRefs(props)
        const store = useLanguage()

        const textStyles = useCssModule()

        const classes = computed(() => {
            return [
                textStyles[`font_${name.value}`],
                textStyles['color'],
                textStyles[`color_${color.value}`],
                textStyles[`align_${props.align}`],
            ]
        })

        const key = computed(() => {
            return props.isAnimated && store.active
        })

        return {
            classes,
            store,
            key,
        }
    },
    components: {
        'transition-fade-component': TransitionFade,
    },
})
</script>

<style lang="scss" module src="@/shared/assets/styles/text.module.scss" />
