import { defineStore } from 'pinia'
import { ref } from 'vue'

import { delay } from '@/packages/utils/debug'

import { api } from '@/entities/order/api'
import { useLanguage } from '@/entities/order/model'

import { useState } from '@/shared/lib/hooks/useState'
import { useTimer } from '@/shared/lib/hooks/useTimer'
import { makeMap } from '@/shared/lib/utils/default/map'
import { useToaster } from '@/shared/lib/hooks/useToaster'

export enum Step {
    FORM_PAY = 1,
    FAQ,
    VERIFICATION,
    SUCCESS,
    ERROR,
    SESSION_EXPIRED,
    FORM_CONFIRM,
    FORM_CONFIRM_SEND,
    ERROR_API,
}

export enum Currency {
    RUB = 1,
}

export enum Status {
    CREATED = 'created',
    PAID = 'paid',
    EXPIRED = 'expired',
    PAYMENT_AMOUNT_INVALID = 'paymentAmountInvalid',
    NOT_FOUND = 'not found',
}

export type Order = {
    id: Maybe<string>
    currency: Maybe<Currency>
    amount: Maybe<string>
    phone: Maybe<string>
    expiresAt: Maybe<TimeStamp>
    status: Maybe<Status>
    recipient: Maybe<string>
}

const initialData: Order = {
    amount: null,
    id: null,
    phone: null,
    expiresAt: null,
    status: null,
    currency: null,
    recipient: null,
}

const mapStep = makeMap<string, Step>({
    [Status.CREATED]: Step.FORM_PAY,
    [Status.PAID]: Step.SUCCESS,
    [Status.EXPIRED]: Step.SESSION_EXPIRED,
    [Status.PAYMENT_AMOUNT_INVALID]: Step.ERROR,
})

export const useStore = defineStore('Store', () => {
    const [step, setStep] = useState(Step.FORM_PAY)
    const order = ref(initialData)
    const timer = ref(0)
    const { displayTime, restart } = useTimer(getPaymentOrder)

    async function waitUpdateStatus() {
        try {
            const { data, status } = await api.getPaymentOrder(order.value.id!)

            if (status === 'error') {
                throw new Error()
            } else if (data?.status) {
                if (data.status === Status.CREATED) {
                    throw new Error('Waiting')
                } else {
                    const currentStep = mapStep.get(data.status.toString())!
                    setStep(currentStep)
                }
            }
        } catch (e) {
            if ((e as Error).message === 'Waiting') {
                await delay(1000 * 15)
                await waitUpdateStatus()
            } else {
                throw new Error()
            }
        }
    }

    async function getPaymentOrder() {
        try {
            const url = new URL(window.location.href)
            const params = new URLSearchParams(url.search)
            const orderId = params.get('orderId')

            if (!orderId) {
                throw new Error()
            }

            const { data, status } = await api.getPaymentOrder(orderId)

            if (status === 'ok' && data) {
                if (data.status === Status.NOT_FOUND) {
                    throw new Error()
                }

                order.value = data

                const currentStep = mapStep.get(order.value.status!.toString())!
                timer.value = Math.floor(
                    Number(order.value.expiresAt! - new Date().getTime()) / 1000
                )

                if (data.status === Status.CREATED) {
                    restart(timer.value)
                    setStep(Step.FORM_PAY)
                } else {
                    setStep(currentStep)
                }
            } else {
                setStep(Step.ERROR_API)
            }
        } catch (e) {
            throw new Error()
        }
    }

    function $reset() {
        setStep(Step.FORM_PAY)
        order.value = initialData
    }

    return {
        $reset,
        step,
        setStep,
        order,
        displayTime,
        waitUpdateStatus,
        getPaymentOrder,
    }
})

export const useFormConfirm = defineStore('FormConfirm', () => {
    const email = ref('')
    const document = ref<Maybe<File>>(null)
    const store = useStore()
    const storeLanguage = useLanguage()
    const { showError } = useToaster()
    const errors = ref({
        email: false,
        document: false,
    })

    function validate() {
        errors.value.email = !email.value
        errors.value.document = !document.value

        return !errors.value.email && !errors.value.document
    }

    async function send() {
        if (validate()) {
            try {
                const formData = new FormData()

                formData.append('email', email.value)
                formData.append('orderId', store.order.id!)
                formData.append('document', document.value as Blob)

                const { status } = await api.sendContacts(formData)
                store.setStep(
                    status === 'ok' ? Step.FORM_CONFIRM_SEND : Step.ERROR_API
                )
            } catch (e) {
                showError(storeLanguage.content.toaster.error)
                throw Error(e as string)
            }
        }
    }

    function $reset() {
        email.value = ''
        document.value = null
    }

    return {
        email,
        document,
        errors,
        send,
        $reset,
    }
})
