// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IQtZj-text-module_font_buttonL,.TxHkq-text-module_font_buttonM,.fryVC-text-module_font_inputM{font-size:1.1666666667rem;line-height:1.6666666667rem}.IQtZj-text-module_font_buttonL{font-weight:500}.CEVgo-text-module_font_titleSmallSemibold{font-size:1.5rem;line-height:2rem;font-weight:600}.ugY8R-text-module_font_headlineMedium{font-size:1.3333333333rem;line-height:1.6666666667rem;font-weight:500}.zpfZZ-text-module_font_subheadSemibold{font-size:1.1666666667rem;line-height:1.6666666667rem;font-weight:600}.QgU6a-text-module_font_captionRegular,.p_xt4-text-module_font_captionSemibold{font-size:1rem;line-height:1.3333333333rem}.p_xt4-text-module_font_captionSemibold{font-weight:600}.DB9M7-text-module_font_captionMiniMedium{font-size:.8333333333rem;line-height:1rem;font-weight:500}.fSA4X-text-module_color{transition:color .3s ease}.k40mY-text-module_color_primary{color:#000}.oQZZb-text-module_color_secondary{color:#737f8c}.Eiu44-text-module_color_inverse{color:#fff}.HIscm-text-module_color_inverse-secondary{color:hsla(0,0%,100%,.75)}.Vne34-text-module_color_warning{color:#f56600}.zCvIt-text-module_color_critical{color:#ff2b00}.giazl-text-module_color_action{color:#0a57f0}.wLBT1-text-module_align_left{text-align:left}.V6i2M-text-module_align_center{text-align:center}.zDqk4-text-module_align_right{text-align:right}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"font_buttonL": "IQtZj-text-module_font_buttonL",
	"font_buttonM": "TxHkq-text-module_font_buttonM",
	"font_inputM": "fryVC-text-module_font_inputM",
	"font_titleSmallSemibold": "CEVgo-text-module_font_titleSmallSemibold",
	"font_headlineMedium": "ugY8R-text-module_font_headlineMedium",
	"font_subheadSemibold": "zpfZZ-text-module_font_subheadSemibold",
	"font_captionRegular": "QgU6a-text-module_font_captionRegular",
	"font_captionSemibold": "p_xt4-text-module_font_captionSemibold",
	"font_captionMiniMedium": "DB9M7-text-module_font_captionMiniMedium",
	"color": "fSA4X-text-module_color",
	"color_primary": "k40mY-text-module_color_primary",
	"color_secondary": "oQZZb-text-module_color_secondary",
	"color_inverse": "Eiu44-text-module_color_inverse",
	"color_inverse-secondary": "HIscm-text-module_color_inverse-secondary",
	"color_warning": "Vne34-text-module_color_warning",
	"color_critical": "zCvIt-text-module_color_critical",
	"color_action": "giazl-text-module_color_action",
	"align_left": "wLBT1-text-module_align_left",
	"align_center": "V6i2M-text-module_align_center",
	"align_right": "zDqk4-text-module_align_right"
};
export default ___CSS_LOADER_EXPORT___;
