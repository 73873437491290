<template lang="pug">
div(
    :class="styles.root"
)
    picture
        source(
            type="image/webp"
            :width="sizes.width"
            :height="sizes.height"
            :data-srcset="`${imagePath}.webp`"
        )
        source(
            :data-srcset="src"
            :width="sizes.width"
            :height="sizes.height"
            :type="`image/${imageFormat}`"
        )
        img(
            v-lazysizes=""
            :width="sizes.width"
            :height="sizes.height"
            :key="'image'"
            :data-src="src"
            :alt="alt"
            crossorigin
        )
</template>

<script lang="ts">
import Vue, { computed, onBeforeUnmount, toRefs, useCssModule } from 'vue'

import { toPxByRem } from '@/shared/lib/utils/default/toPxByRem'
import { useState } from '@/shared/lib/hooks/useState'

export type TheImageProps = {
    src: string
    alt: string
    isOneFormat?: boolean
    width?: number
    height?: number
}

function getImageFormatBySrc(src: string) {
    const dotIndex = src.lastIndexOf('.')

    return src.slice(dotIndex, src.length)
}

function getImagePath(src: string) {
    const dotIndex = src.lastIndexOf('.')

    return src.slice(0, dotIndex)
}

export default Vue.extend({
    name: 'TheImage',
    props: {
        src: {
            type: String,
        },
        alt: {
            type: String,
        },
        width: {
            type: Number,
        },
        height: {
            type: Number,
        },
    },
    setup(props) {
        const styles = useCssModule()

        const { src } = toRefs(props)

        const imageFormat = computed(() => getImageFormatBySrc(src.value))
        const imagePath = computed(() => getImagePath(src.value))
        const [sizes, setSizes] = useState({})

        function onResize() {
            setSizes({
                width: toPxByRem(props.width),
                height: toPxByRem(props.height),
            })
        }

        function onCreated() {
            window.addEventListener('resize', onResize, { passive: true })
            onResize()
        }

        onCreated()

        onBeforeUnmount(() => {
            window.removeEventListener('resize', onResize)
        })

        return {
            styles,
            imageFormat,
            imagePath,
            sizes,
        }
    },
})
</script>

<style module lang="scss" src="./TheImage.module.scss" />
