<template lang="pug">
component(
    :is="tag"
    v-bind="properties"
    v-on:click="onClick"
)
    div(
        :class="[styles.content, containerClass ? containerClass : '']"
    )
        slot(
            name="content"
        )
    transition(
        name="fade"
        mode="out-in"
    )
        div(
            v-if="isLoading"
            :class="styles.loader"
        )
            slot(
                name="loader"
            )
</template>

<script lang="ts">
import Vue, { computed, PropType, toRefs } from 'vue'

import { propsDefault } from '@/shared/lib/vue-utils/propsDefault'
import { ButtonBaseEmits, ButtonBaseProps } from '@/shared/ui/ButtonBase/model'
import getOptionsByTag from '@/shared/lib/utils/default/getOptionsByTag'

import styles from './ButtonBase.module.scss'

export const buttonBaseProps = {
    tag: {
        type: String as PropType<ButtonBaseProps['tag']>,
        default: 'button',
    },
    href: {
        type: String as PropType<ButtonBaseProps['href']>,
    },
    type: {
        type: String as PropType<ButtonBaseProps['type']>,
        default: 'button',
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    isDisabled: {
        type: Boolean,
        default: false,
    },
    containerClass: {
        type: String,
    },
}

export default Vue.extend({
    name: 'ButtonBase',
    props: {
        ...buttonBaseProps,
        ...propsDefault,
    },
    setup(props, { emit }: { emit: ButtonBaseEmits }) {
        const { isLoading, isDisabled, tag, type, testId } = toRefs(props)

        const classes = computed(() => [
            styles.root,
            isLoading.value && styles['is-loading'],
            isDisabled.value && styles['is-disabled'],
        ])

        const properties = computed(() => ({
            class: classes.value,
            'data-testid': testId.value,
            ...(tag.value === 'button' && {
                type: type.value,
            }),
            ...getOptionsByTag(
                props.tag || buttonBaseProps.tag.default,
                props.href
            ),
            disabled: isLoading.value || isDisabled.value,
        }))

        function onClick(e: Event) {
            if (isDisabled.value || isLoading.value) return

            return emit('click', e)
        }

        return {
            properties,
            onClick,
            styles,
        }
    },
})
</script>
