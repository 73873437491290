import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useState } from '@/shared/lib/hooks/useState'

import { content as languageContent, Language } from './data'

const languages = [
    {
        id: Language.RUS,
        text: 'RUS',
    },
    {
        id: Language.ENG,
        text: 'ENG',
    },
]

const initialLanguage = localStorage.getItem('language')
    ? (Number(localStorage.getItem('language')) as Language)
    : Language.RUS

export const useLanguage = defineStore('Language', () => {
    const [active, setActive] = useState(initialLanguage)
    const content = computed(() => {
        return languageContent.get(active.value.toString())!
    })

    function $reset() {
        setActive(initialLanguage)
    }

    function onSetActive(value: Language) {
        setActive(value)
        localStorage.setItem('language', value.toString())
    }

    return {
        $reset,
        languages,
        active,
        content,
        setActive: onSetActive,
    }
})
