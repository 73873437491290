export function getOptionsByTag(tag: string, href?: string) {
    switch (tag) {
        case 'button': {
            return {
                role: 'button',
                type: 'button',
            }
        }
        case 'router-link': {
            return {
                to: href ?? {},
            }
        }
        case 'a': {
            return {
                href,
                ...(!(
                    href?.indexOf('tel:') !== -1 ||
                    href?.indexOf('mailto:') !== -1
                ) && {
                    rel: 'noopener noreferrer',
                    target: '_blank',
                }),
            }
        }
        default:
            return {}
    }
}

export default getOptionsByTag
