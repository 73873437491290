// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cK_yY-CardFile-module_button{min-width:100%}.UrpTg-CardFile-module_placeholder{text-align:center}.UrpTg-CardFile-module_placeholder button{display:inline-block}.wWwVK-CardFile-module_button-delete,.NhOyS-CardFile-module_icon,.ecQWP-CardFile-module_information{display:inline-block;vertical-align:middle;font-size:0}.ecQWP-CardFile-module_information{width:calc(100% - 6.66667rem);margin:0 .6666666667rem;text-align:left}.jOP7I-CardFile-module_text-placeholder{margin-top:.6666666667rem}.gBaU4-CardFile-module_file-name{margin-top:.3333333333rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "cK_yY-CardFile-module_button",
	"placeholder": "UrpTg-CardFile-module_placeholder",
	"button-delete": "wWwVK-CardFile-module_button-delete",
	"icon": "NhOyS-CardFile-module_icon",
	"information": "ecQWP-CardFile-module_information",
	"text-placeholder": "jOP7I-CardFile-module_text-placeholder",
	"file-name": "gBaU4-CardFile-module_file-name"
};
export default ___CSS_LOADER_EXPORT___;
