<template lang="pug">
button(
    :class="classes"
    v-on:click="$emit('click')"
)
    icon-base-component(
        :class="styles.icon"
        :name="icon"
        size="16"
    )
</template>

<script lang="ts">
import Vue, { computed, PropType, useCssModule } from 'vue'

import { IconBase, IconName } from '@/shared/ui/IconBase'

export type ButtonIconProps = {
    icon: IconName
    view: 'inverse' | 'critical-flat'
}

export default Vue.extend({
    name: 'ButtonIcon',
    props: {
        icon: {
            type: String as PropType<ButtonIconProps['icon']>,
        },
        view: {
            type: String as PropType<ButtonIconProps['view']>,
            default: 'inverse',
        },
    },
    setup(props) {
        const styles = useCssModule()
        const classes = computed(() => {
            return [styles.root, styles[`view_${props.view}`]]
        })

        return {
            styles,
            classes,
        }
    },
    components: {
        'icon-base-component': IconBase,
    },
})
</script>

<style module lang="scss" src="./ButtonIcon.module.scss" />
