<template lang="pug">
image-svg-component(
    :width="sizes.width"
    :height="sizes.height"
    :style="{width: `${sizes.width}px`, height: `${sizes.height}px`}"
    :src="src"
    alt="Иконка"
)
</template>

<script lang="ts">
import Vue, { computed, onBeforeUnmount, PropType, toRefs } from 'vue'

import { getResources } from '@/packages/getResources'

import { ImageSvg } from '@/shared/ui/ImageSvg'
import { icons } from '@/shared/ui/IconBaseImage'
import { toPxByRem } from '@/shared/lib/utils/default/toPxByRem'
import { useState } from '@/shared/lib/hooks/useState'

export type IconBaseImageProps = {
    size: number
    name: (typeof icons)[number]
}

export default Vue.extend({
    name: 'IconBaseImage',
    props: {
        name: {
            type: String as PropType<IconBaseImageProps['name']>,
            required: true,
        },
        size: {
            type: [Number, String] as PropType<IconBaseImageProps['size']>,
            required: true,
        },
    },
    setup(props) {
        const { name } = toRefs(props)

        const src = computed(() => {
            return getResources(`images/svg-icon/${name.value}.svg`)
        })
        const [sizes, setSizes] = useState<
            Record<'width' | 'height', Maybe<number>>
        >({
            width: null,
            height: null,
        })

        function onResize() {
            setSizes({
                width: toPxByRem(props.size),
                height: toPxByRem(props.size),
            })
        }

        function onCreated() {
            window.addEventListener('resize', onResize, { passive: true })
            onResize()
        }

        onCreated()

        onBeforeUnmount(() => {
            window.removeEventListener('resize', onResize)
        })

        return {
            src,
            sizes,
        }
    },
    components: {
        'image-svg-component': ImageSvg,
    },
})
</script>
