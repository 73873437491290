<template lang="pug">
response-component(
    view="error"
    :title="content.errorApi.title"
    :subtitle="content.errorApi.text"
)
</template>

<script lang="ts">
import Vue from 'vue'
import { storeToRefs } from 'pinia'

import { useLanguage } from '@/entities/order/model'

import { Response } from '@/shared/ui/Response'

export default Vue.extend({
    name: 'OrderErrorApi',
    setup() {
        const storeLanguage = useLanguage()
        const { content } = storeToRefs(storeLanguage)

        return {
            content,
        }
    },
    components: {
        'response-component': Response,
    },
})
</script>
