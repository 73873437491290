import { render, staticRenderFns } from "./App.vue?vue&type=template&id=5f35186a&lang=pug"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"
import style0 from "./app.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./app-module.scss?vue&type=style&index=1&prod&module=true&lang=scss&external"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports