// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Bf74q-Response-module_root{text-align:center;font-size:0}.WjYIb-Response-module_icon,.rglcR-Response-module_loader{margin-bottom:1.6666666667rem}.rglcR-Response-module_loader{display:inline-block}.fo8XB-Response-module_title{margin-bottom:.6666666667rem}.imL94-Response-module_text{margin-top:1.6666666667rem}.g8Xlp-Response-module_description{margin-top:.6666666667rem}.FauXU-Response-module_button{display:inline-block;width:100%;margin-top:1.6666666667rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Bf74q-Response-module_root",
	"icon": "WjYIb-Response-module_icon",
	"loader": "rglcR-Response-module_loader",
	"title": "fo8XB-Response-module_title",
	"text": "imL94-Response-module_text",
	"description": "g8Xlp-Response-module_description",
	"button": "FauXU-Response-module_button"
};
export default ___CSS_LOADER_EXPORT___;
