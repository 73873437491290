// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G41M3-ButtonBase-module_root{display:block;text-align:center;cursor:pointer}._JpTl-ButtonBase-module_content,.G41M3-ButtonBase-module_root{position:relative}._JpTl-ButtonBase-module_content,.SN8J4-ButtonBase-module_loader{transition:opacity .3s ease}.SN8J4-ButtonBase-module_loader{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);opacity:0}.wwNox-ButtonBase-module_is-disabled.G41M3-ButtonBase-module_root,.KlJjl-ButtonBase-module_is-loading.G41M3-ButtonBase-module_root{pointer-events:none}.KlJjl-ButtonBase-module_is-loading .SN8J4-ButtonBase-module_loader{opacity:1}.KlJjl-ButtonBase-module_is-loading ._JpTl-ButtonBase-module_content{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "G41M3-ButtonBase-module_root",
	"content": "_JpTl-ButtonBase-module_content",
	"loader": "SN8J4-ButtonBase-module_loader",
	"is-disabled": "wwNox-ButtonBase-module_is-disabled",
	"is-loading": "KlJjl-ButtonBase-module_is-loading"
};
export default ___CSS_LOADER_EXPORT___;
