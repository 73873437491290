// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hatqf-FormPay-module_subtitle,.SsZbl-FormPay-module_title{margin-bottom:1rem}.ntCe0-FormPay-module_button-faq{min-width:100%;margin-bottom:2rem}.EKN1g-FormPay-module_card{margin-bottom:1rem;text-align:left}.Jx95k-FormPay-module_order-id{margin-bottom:2rem}._cVBA-FormPay-module_button-pay{min-width:100%;margin-bottom:.6666666667rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "Hatqf-FormPay-module_subtitle",
	"title": "SsZbl-FormPay-module_title",
	"button-faq": "ntCe0-FormPay-module_button-faq",
	"card": "EKN1g-FormPay-module_card",
	"order-id": "Jx95k-FormPay-module_order-id",
	"button-pay": "_cVBA-FormPay-module_button-pay"
};
export default ___CSS_LOADER_EXPORT___;
