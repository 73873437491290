<template lang="pug">
response-component(
    isAction
    view="error"
    :title="content.sessionError.title"
    :text="`${content.sessionError.orderId} ${store.order.id}`"
    :description="`${content.sessionError.amount} ${store.order.amount}`"
    :action-text="content.sessionError.button"
    @click="onClick"
)
</template>

<script lang="ts">
import Vue from 'vue'
import { storeToRefs } from 'pinia'

import { Step, useLanguage, useStore } from '@/entities/order/model'

import { Response } from '@/shared/ui/Response'

export default Vue.extend({
    name: 'OrderSessionError',
    setup() {
        const store = useStore()
        const storeLanguage = useLanguage()
        const { content } = storeToRefs(storeLanguage)

        function onClick() {
            store.setStep(Step.FORM_CONFIRM)
        }

        return {
            content,
            store,
            onClick,
        }
    },
    components: {
        'response-component': Response,
    },
})
</script>
