// eslint-disable-next-line
export function entries<T extends Record<any, any>>(obj: T) {
    return Object.entries(obj) as unknown as [keyof T, T[keyof T]][]
}

/** Возвращает новый объект с ключами НЕ входящими в массив */
export const omit = <T extends Record<string, unknown>, K extends keyof T>(
    target: T,
    omitFields: readonly K[]
) => {
    const targetKeys = Object.keys(target) as K[]
    const filteredFields = targetKeys.filter(key => !omitFields.includes(key))

    return Object.fromEntries(
        filteredFields.map(key => [key, target[key]])
    ) as unknown as Omit<T, K>
}

/** Возвращает новый объект с ключами входящими в массив */
export const pick = <T extends Record<string, unknown>, K extends keyof T>(
    target: T,
    pickFields: readonly K[]
) =>
    Object.fromEntries(
        pickFields.map(key => [key, target[key]])
    ) as unknown as Pick<T, K>

export function keys<T extends object>(obj: T) {
    return Object.keys(obj) as unknown as (keyof T)[]
}
