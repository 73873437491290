// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._ODzd-AccountDetail-module_is-clickable{cursor:pointer}.GlsI4-AccountDetail-module_label{margin-bottom:-.1666666667rem}.gZeZ6-AccountDetail-module_title{margin-right:.6666666667rem;line-height:2.3333333333rem;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:100%}.zlT2h-AccountDetail-module_button,.gZeZ6-AccountDetail-module_title{display:inline-block;vertical-align:middle}.B8PC5-AccountDetail-module_content{white-space:nowrap}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"is-clickable": "_ODzd-AccountDetail-module_is-clickable",
	"label": "GlsI4-AccountDetail-module_label",
	"title": "gZeZ6-AccountDetail-module_title",
	"button": "zlT2h-AccountDetail-module_button",
	"content": "B8PC5-AccountDetail-module_content"
};
export default ___CSS_LOADER_EXPORT___;
