import { env } from '@/shared/config'

type requestOptions = {
    url: string
    method: 'GET' | 'POST' | 'PUT' | 'DELETE'
    data?: FormData
}

export async function createRequest(options: requestOptions) {
    try {
        const { url, method, data } = options

        const response = await fetch(`${env.API_DOMAIN}/api/${url}`, {
            method,
            body: data,
        })

        if (!response.ok) {
            throw new Error()
        }

        return await response.json()
    } catch (e) {
        throw new Error(e as string)
    }
}
