<template lang="pug">
segment-control-component(
    :value="active"
    :list="languages"
    @input="store.setActive"
)
</template>

<script lang="ts">
import Vue from 'vue'
import { storeToRefs } from 'pinia'

import { useLanguage } from '@/entities/order/model'

import { SegmentControl } from '@/shared/ui/SegmentControl'

export default Vue.extend({
    name: 'LanguageSwitcher',
    setup() {
        const store = useLanguage()
        const { active, languages } = storeToRefs(store)

        return {
            active,
            languages,
            store,
        }
    },
    components: {
        'segment-control-component': SegmentControl,
    },
})
</script>
