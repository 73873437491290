<template lang="pug">
div(
    :class="styles.root"
)
    transition-fade-component
        language-switcher-component(
            v-if="status !== 'loading'"
            :class="styles['language-switcher']"
        )
    div(
        :class="styles.content"
    )
        transition-fade-component
            loader-component(
                v-if="status === 'loading'"
                :class="styles.loader"
            )
            div(
                v-else-if="status === 'success'"
            )
                transition-fade-component
                    form-pay-component(
                        v-if="step === Step.FORM_PAY"
                    )
                    faq-component(
                        v-else-if="step === Step.FAQ"
                    )
                    verification-component(
                        v-else-if="step === Step.VERIFICATION"
                        :class="styles.centered"
                    )
                    success-component(
                        v-else-if="step === Step.SUCCESS"
                        :class="styles.centered"
                    )
                    error-component(
                        v-else-if="step === Step.ERROR"
                        :class="styles.centered"
                    )
                    session-error-component(
                        v-else-if="step === Step.SESSION_EXPIRED"
                        :class="styles.centered"
                    )
                    form-confirm-component(
                        v-else-if="step === Step.FORM_CONFIRM"
                    )
                    form-confirm-send-component(
                        v-else-if="step === Step.FORM_CONFIRM_SEND"
                        :class="styles.centered"
                    )
                    error-api-component(
                        v-else-if="step === Step.ERROR_API"
                        :class="styles.centered"
                    )
            error-id-component(
                v-else-if="status === 'error'"
                :class="styles.centered"
            )
</template>

<script lang="ts">
import Vue, { useCssModule } from 'vue'
import { storeToRefs } from 'pinia'

import { Step, useStore } from '@/entities/order/model'
import { FormConfirmSend } from '@/entities/order/ui/Layout/FormConfirmSend'

import { TransitionFade } from '@/shared/ui/transitions/TransitionFade'
import { useFetch } from '@/shared/lib/hooks/useFetch'
import { Loader } from '@/shared/ui/Loader'

import { LanguageSwitcher } from './LanguageSwitcher'
import { FormPay } from './FormPay'
import { FAQ } from './FAQ'
import { Verification } from './Verification'
import { Success } from './Success'
import { Error } from './Error'
import { FormConfirm } from './FormConfirm'
import { SessionError } from './SessionError'
import { ErrorId } from './ErrorId'
import { ErrorApi } from './ErrorApi'

export default Vue.extend({
    name: 'OrderLayout',
    setup() {
        const styles = useCssModule()
        const store = useStore()
        const { step } = storeToRefs(store)
        const { fetch, status } = useFetch(store.getPaymentOrder)

        async function onCreated() {
            try {
                await fetch()
            } catch (e) {
                console.log(e)
            }
        }

        onCreated()

        return {
            styles,
            step,
            Step,
            status,
        }
    },
    components: {
        'language-switcher-component': LanguageSwitcher,
        'form-pay-component': FormPay,
        'faq-component': FAQ,
        'verification-component': Verification,
        'success-component': Success,
        'error-component': Error,
        'session-error-component': SessionError,
        'form-confirm-component': FormConfirm,
        'form-confirm-send-component': FormConfirmSend,
        'error-id-component': ErrorId,
        'error-api-component': ErrorApi,
        'transition-fade-component': TransitionFade,
        'loader-component': Loader,
    },
})
</script>

<style module lang="scss" src="./Layout.module.scss" />
