<template lang="pug">
blockquote(
    :class="styles.root"
)
    icon-base-component(
        :class="styles.icon"
        size="16"
        name="icon-alert_outline"
        color="warning"
    )
    text-component(
        :class="styles.text"
        name="captionRegular"
        color="warning"
    )
        slot
</template>

<script lang="ts">
import Vue, { useCssModule } from 'vue'

import { TheText } from '@/shared/ui/TheText'
import { IconBase } from '@/shared/ui/IconBase'

export default Vue.extend({
    name: 'CardInformer',
    setup() {
        const styles = useCssModule()

        return {
            styles,
        }
    },
    components: {
        'text-component': TheText,
        'icon-base-component': IconBase,
    },
})
</script>

<style module lang="scss" src="./CardInformer.module.scss" />
