<template lang="pug">
response-component(
    isLoading
    :title="content.verification.title"
    :text="`${content.verification.orderId} ${store.order.id}`"
    :description="`${content.verification.amount} ${store.order.amount}`"
)
</template>

<script lang="ts">
import Vue from 'vue'
import { storeToRefs } from 'pinia'

import { Step, useLanguage, useStore } from '@/entities/order/model'

import { Response } from '@/shared/ui/Response'

export default Vue.extend({
    name: 'OrderVerification',
    setup() {
        const storeLanguage = useLanguage()
        const store = useStore()
        const { content } = storeToRefs(storeLanguage)

        async function startUpdateStatus() {
            try {
                await store.waitUpdateStatus()
            } catch (e) {
                store.setStep(Step.FORM_PAY)
            }
        }

        startUpdateStatus()

        return {
            content,
            store,
        }
    },
    components: {
        'response-component': Response,
    },
})
</script>
