const makeMap = <K extends PropertyKey, V>(
    obj: Record<K, NonNullable<V>>
): Map<string, V> => {
    const entries = Object.entries(obj).map(([key, value]) => [
        key.toString(),
        value,
    ])
    // @ts-ignore
    return new Map(entries)
}

const makeWeakMap = <V = unknown>(obj: Record<string, V>) =>
    // eslint-disable-next-line
    new WeakMap<any, V>(Object.entries(obj))

const mapReplacer = <V>(key: string, value: Record<string, V>) => {
    if (value instanceof Map) {
        return { __type: 'Map', value: Object.fromEntries(value) }
    }
    return value
}

// eslint-disable-next-line
const mapReviver = (key: string, value?: { __type?: 'Map'; value: any }) => {
    if (value?.__type === 'Map') {
        return new Map(Object.entries(value.value))
    }
    return value
}

export { makeMap, makeWeakMap, mapReplacer, mapReviver }
