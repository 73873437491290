import { makeMap } from '@/shared/lib/utils/default/map'

export type Content = {
    formPay: {
        title: string
        subtitleStart: string
        subtitleEnd: string
        buttonToFaq: string
        card: {
            phone: string
            bank: string
            sum: string
        }
        orderId: string
        buttonPay: string
        timer: string
    }
    faq: {
        buttonTop: string
        title: string
        cardLabel: string
        cards: string[]
        information: string
        buttonBottom: string
    }
    verification: {
        title: string
        orderId: string
        amount: string
    }
    success: {
        title: string
        orderId: string
        amount: string
    }
    error: {
        title: string
        orderId: string
        amount: string
        button: string
    }
    sessionError: {
        title: string
        orderId: string
        amount: string
        button: string
    }
    formConfirm: {
        buttonTop: string
        title: string
        orderId: string
        inputPlaceholder: string
        buttonBottom: string
        terms: string
        termsLink: string
    }
    formConfirmSend: {
        title: string
        description: string
        orderId: string
    }
    errorId: {
        title: string
        text: string
    }
    errorApi: {
        title: string
        text: string
    }
    dropzone: {
        text: string
        error: {
            format: string
            size: string
        }
    }
    cardFile: {
        placeholder: {
            button: string
            text: string
        }
        dropped: {
            text: string
        }
    }
    toaster: {
        copy: string
        error: string
    }
}

export enum Language {
    RUS = 1,
    ENG,
}

export const content = makeMap<Language, Content>({
    [Language.RUS]: {
        formPay: {
            title: 'Оплата через Т-банк',
            subtitleStart: 'Зайдите в Т-банк и переведите',
            subtitleEnd: 'по номеру телефона из формы ниже',
            buttonToFaq: 'Как оплатить платёж?',
            card: {
                phone: 'На номер',
                bank: 'Банк получателя',
                sum: 'Сумма',
            },
            orderId: 'Order id:',
            buttonPay: 'Я оплатил',
            timer: 'Оплата действительна в течение',
        },
        faq: {
            buttonTop: 'Вернуться к оплате',
            title: 'Как оплатить заказ?',
            cardLabel: 'Шаг',
            cards: [
                'Откройте приложение Тинькофф (Т-банк)',
                'Перейдите в раздел «Платежи»',
                'Перейдите в форму перевода по телефону',
                'Нажмите на иконку флага, чтобы выбрать другую страну',
                'Выберите Кыргызстан, введите нужный номер телефона и перейдите к оплате',
                'Укажите сумму, нажмите «Другой банк» и выберите необходимый банк',
                'Проверьте данные и нажмите «Перевести»',
            ],
            information: 'Ошибочный платёж в другой банк возврату не подлежит',
            buttonBottom: 'Перейти к оплате',
        },
        verification: {
            title: 'Идёт проверка оплаты',
            orderId: 'Order id:',
            amount: 'Заказ на сумму',
        },
        success: {
            title: 'Оплата прошла успешно',
            orderId: 'Order id:',
            amount: 'Заказ на сумму',
        },
        error: {
            title: 'Не удалось подтвердить оплату',
            orderId: 'Order id:',
            amount: 'Заказ на сумму',
            button: 'Отправить чек об операции',
        },
        sessionError: {
            title: 'Время сессии для оплаты истекло',
            orderId: 'Order id:',
            amount: 'Заказ на сумму',
            button: 'Отправить чек об операции',
        },
        formConfirm: {
            buttonTop: 'Вернуться к оплате',
            title: 'Заполните форму для подтверждения оплаты',
            orderId: 'Order id:',
            inputPlaceholder: 'Введите адрес эл. почты',
            buttonBottom: 'Отправить запрос',
            terms: 'Нажимая кнопку вы соглашаетесь c',
            termsLink: 'условиями пользовательского соглашения',
        },
        formConfirmSend: {
            title: 'Ваше сообщение отправлено',
            description:
                'Мы рассмотрим ваше обращение и ответим письмом на электронную почту',
            orderId: 'Order id:',
        },
        errorId: {
            title: 'Такой страницы не найдено',
            text: 'Убедитесь, что введённая ссылка правильная, и попробуйте снова',
        },
        errorApi: {
            title: 'Произошла ошибка при загрузке',
            text: 'Попробуйте снова немного позже',
        },
        dropzone: {
            text: 'Перетащите файл сюда',
            error: {
                format: 'Файл должен быть в формате pdf, docx, jpg или png',
                size: 'Файл должен быть не более 5Мб',
            },
        },
        cardFile: {
            placeholder: {
                button: 'Загрузить документ об оплате',
                text: 'Или перетащите файл сюда',
            },
            dropped: {
                text: 'Документ об оплате',
            },
        },
        toaster: {
            copy: 'Скопировано',
            error: 'Что-то пошло не так',
        },
    },
    [Language.ENG]: {
        formPay: {
            title: 'Order payment via T-bank',
            subtitleStart: 'Go to T-bank and transfer',
            subtitleEnd: 'to the phone number from the form below',
            buttonToFaq: 'How to make a payment?',
            card: {
                phone: 'To the number',
                bank: 'Recipient bank',
                sum: 'Amount',
            },
            orderId: 'Order id:',
            buttonPay: 'I have paid',
            timer: 'Payment is valid for ',
        },
        faq: {
            buttonTop: 'Return to payment',
            title: 'How to pay for the order?',
            cardLabel: 'Step',
            cards: [
                'Open the Tinkoff app (T-bank)',
                'Go to the "Payments" section',
                'Go to the phone transfer form',
                'Click on the flag icon to select another country',
                'Select Kyrgyzstan, enter the required phone number, and proceed to payment',
                'Enter the amount, click "Other bank," and select the required bank',
                'Check the details and click "Transfer"',
            ],
            information: 'Incorrect payment to another bank is non-refundable',
            buttonBottom: 'Go to payment',
        },
        verification: {
            title: 'Payment verification in progress',
            orderId: 'Order id:',
            amount: 'Order for the amount of',
        },
        success: {
            title: 'Payment was successful',
            orderId: 'Order id:',
            amount: 'Order for the amount of',
        },
        error: {
            title: 'Unable to confirm payment',
            orderId: 'Order id:',
            amount: 'Order for the amount of',
            button: 'Send operation receipt',
        },
        sessionError: {
            title: 'Session expired',
            orderId: 'Order id:',
            amount: 'Order for the amount of',
            button: 'Send operation receipt',
        },
        formConfirm: {
            buttonTop: 'Return to payment',
            title: 'Fill out the form',
            orderId: 'Order id:',
            inputPlaceholder: 'Enter your email address',
            buttonBottom: 'Submit',
            terms: 'By clicking the button, you agree to',
            termsLink: 'the terms of the user agreement',
        },
        formConfirmSend: {
            title: 'Your message has been sent',
            description: 'We will review your request and respond by email',
            orderId: 'Order id:',
        },
        errorId: {
            title: 'Page Not Found',
            text: 'Please check the entered link and try again',
        },
        errorApi: {
            title: 'An error occurred during loading',
            text: 'Please try again later',
        },
        dropzone: {
            text: 'Drop a file',
            error: {
                format: 'The file must be in pdf, docx, jpg, or png format',
                size: 'The file must be no larger than 5MB',
            },
        },
        cardFile: {
            placeholder: {
                button: 'Upload the payment document',
                text: 'or drop a file',
            },
            dropped: {
                text: 'Operation receipt',
            },
        },
        toaster: {
            copy: 'Copied',
            error: 'Something went wrong',
        },
    },
})
