<template lang="pug">
img.lazyload(
    v-lazysizes=""
    :class="styles.root"
    :data-src="src"
    :alt="alt"
    :width="size.width"
    :height="size.height"
)
</template>

<script lang="ts">
import Vue, { onMounted, onUnmounted, useCssModule } from 'vue'

import { toPxByRem } from '@/shared/lib/utils/default/toPxByRem'
import { useState } from '@/shared/lib/hooks/useState'

export type ImageSvgProps = {
    src: string
    alt: string
    width?: number
    height?: number
}

export default Vue.extend({
    name: 'ImageSvg',
    props: {
        src: {
            type: String,
        },
        alt: {
            type: String,
        },
        width: {
            type: [Number, String],
        },
        height: {
            type: [Number, String],
        },
    },
    setup({ width, height }) {
        const styles = useCssModule()
        const [size, setSize] = useState<
            Record<'width' | 'height', Maybe<number>>
        >({
            width: null,
            height: null,
        })

        onMounted(onResize)
        onUnmounted(() => {
            window.removeEventListener('resize', onResize)
        })

        function onResize() {
            setSize({
                width: toPxByRem(parseFloat(width.toString())),
                height: toPxByRem(parseFloat(height.toString())),
            })
        }

        window.addEventListener('resize', onResize, { passive: true })

        return {
            styles,
            size,
        }
    },
})
</script>

<style module lang="scss" src="./ImageSvg.module.scss" />
