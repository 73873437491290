<template>
    <i :class="classes" :style="rootStyles" v-on="$listeners" />
</template>

<script lang="ts">
import Vue, { computed, PropType, toRefs, useCssModule } from 'vue'

import { asRem, textStyles } from '@/shared/lib/styles'

import { IconBaseProps } from './index'

export default Vue.extend({
    name: 'IconBase',
    props: {
        name: {
            type: String as PropType<IconBaseProps['name']>,
            required: true,
        },
        color: {
            type: String as PropType<IconBaseProps['color']>,
            default: 'inherit',
        },
        size: {
            type: [Number, String] as PropType<IconBaseProps['size']>,
        },
        isClickable: {
            type: Boolean as PropType<IconBaseProps['isClickable']>,
        },
    },
    setup(props) {
        const styles = useCssModule()

        const { name, isClickable } = toRefs(props)

        const classes = computed(() => [
            styles.root,
            textStyles[`color_${props.color}`],
            name.value,
            isClickable.value && styles['is-clickable'],
        ])

        const rootStyles = computed(() => {
            const { size } = props

            if (!size) return {}

            return { 'font-size': asRem(Number(size)) }
        })

        return {
            classes,
            rootStyles,
        }
    },
})
</script>

<style module lang="scss" src="./IconBase.module.scss" />
