<template lang="pug">
    label(
        :class="styles.root"
    )
        input(
            :class="classes"
            :value="value"
            :placeholder="placeholder"
            v-on:input="onInput"
        )
</template>

<script lang="ts">
import Vue, { computed, PropType, useCssModule, toRefs } from 'vue'

export type InputProps = {
    value: string
    placeholder: string
    isError: boolean
}
export type InputEmits = {
    (event: 'input', value: InputProps['value']): void
    (event: 'update:isError', value: boolean): void
}

export default Vue.extend({
    name: 'Input',
    props: {
        value: {
            type: String as PropType<InputProps['value']>,
        },
        placeholder: {
            type: String as PropType<InputProps['placeholder']>,
        },
        isError: {
            type: Boolean as PropType<InputProps['isError']>,
        },
    },
    setup(props, { emit }: { emit: InputEmits }) {
        const styles = useCssModule()

        const { isError } = toRefs(props)

        function onInput(e: InputEvent) {
            const value = (e.target as HTMLInputElement).value
            emit('input', value)
            emit('update:isError', false)
        }

        const classes = computed(() => {
            return [styles.input, isError.value && styles['is-error']]
        })

        return {
            styles,
            onInput,
            classes,
        }
    },
})
</script>

<style module lang="scss" src="./Input.module.scss" />
