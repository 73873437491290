// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yKYFi-Layout-module_root{min-width:320px;width:100%;max-width:26.6666666667rem;padding:0 1.6666666667rem;margin:0 auto;padding-top:1.6666666667rem;padding-bottom:1.6666666667rem;min-height:100vh;box-sizing:border-box;text-align:center}.sFkQE-Layout-module_content{position:relative;min-height:calc(100vh - 7.33333rem)}.AuPTP-Layout-module_language-switcher{margin-bottom:2rem}.u5C7f-Layout-module_centered{width:100%}.u5C7f-Layout-module_centered,.eDj1m-Layout-module_loader{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}.eDj1m-Layout-module_loader{position:fixed}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "yKYFi-Layout-module_root",
	"content": "sFkQE-Layout-module_content",
	"language-switcher": "AuPTP-Layout-module_language-switcher",
	"centered": "u5C7f-Layout-module_centered",
	"loader": "eDj1m-Layout-module_loader"
};
export default ___CSS_LOADER_EXPORT___;
