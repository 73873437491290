<template lang="pug">
    response-component(
        view="icon"
        :title="content.formConfirmSend.title"
        :text="`${content.formConfirmSend.orderId} ${store.order.id}`"
        :subtitle="content.formConfirmSend.description"
    )
</template>

<script lang="ts">
import Vue from 'vue'
import { storeToRefs } from 'pinia'

import { useLanguage, useStore } from '@/entities/order/model'

import { Response } from '@/shared/ui/Response'

export default Vue.extend({
    name: 'OrderFormConfirmSend',
    setup() {
        const storeLanguage = useLanguage()
        const store = useStore()
        const { content } = storeToRefs(storeLanguage)

        return {
            content,
            store,
        }
    },
    components: {
        'response-component': Response,
    },
})
</script>
