// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PeroQ-SegmentControl-module_root{border:1px solid #e9ebed;background-color:#e9ebed;border-radius:.6666666667rem;display:inline-block;font-size:0}.PeroQ-SegmentControl-module_root,.OqW7Y-SegmentControl-module_segment{position:relative}.OqW7Y-SegmentControl-module_segment{padding:.4166666667rem .6666666667rem;z-index:1}.mkeav-SegmentControl-module_caret{position:absolute;top:0;height:100%;background-color:#fff;border-radius:calc(.66667rem - 1px);z-index:0;transition:transform .3s ease;will-change:transform}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PeroQ-SegmentControl-module_root",
	"segment": "OqW7Y-SegmentControl-module_segment",
	"caret": "mkeav-SegmentControl-module_caret"
};
export default ___CSS_LOADER_EXPORT___;
