<template lang="pug">
article(
    :class="classes"
)
    text-component(
        :class="styles.label"
        name="captionRegular"
        color="secondary"
    ) {{ label }}
    text-component(
        :class="styles.title"
        name="subheadSemibold"
    ) {{ title }}
    image-component(
        v-if="image"
        :class="styles.image"
        :src="imageSrc"
        :alt="`Guide ${image}`"
        :width="imageSizes.width"
        :height="imageSizes.height"
    )
</template>

<script lang="ts">
import Vue, { computed, PropType, useCssModule } from 'vue'

import { getResources } from '@/packages/getResources'

import { useLanguage } from '@/entities/order/model'

import { TheText } from '@/shared/ui/TheText'
import { TheImage } from '@/shared/ui/TheImage'

type Images = number

export type CardGuideProps = {
    label: string
    title: string
    image?: Images
}

export default Vue.extend({
    name: 'CardGuide',
    props: {
        label: {
            type: String as PropType<CardGuideProps['label']>,
            required: true,
        },
        title: {
            type: String as PropType<CardGuideProps['title']>,
            required: true,
        },
        image: {
            type: Number as PropType<CardGuideProps['image']>,
        },
    },
    setup(props) {
        const styles = useCssModule()
        const store = useLanguage()
        const imageSrc = computed(() => {
            return getResources(
                `images/guide/${store.active}/${props.image}.png`
            )
        })

        const classes = computed(() => {
            return [styles.root, !props.image && styles['is-bottom-space']]
        })

        const imageSizes = computed(() => {
            let height

            switch (props.image) {
                case 1:
                case 2:
                case 3:
                case 4:
                    height = 85
                    break
                case 5:
                    height = 110
                    break
                case 6:
                    height = 130
                    break
                default:
                    break
            }
            return {
                width: 248,
                height,
            }
        })

        return {
            styles,
            imageSrc,
            imageSizes,
            classes,
        }
    },
    components: {
        'text-component': TheText,
        'image-component': TheImage,
    },
})
</script>

<style module lang="scss" src="./CardGuide.module.scss" />
