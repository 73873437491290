import { DeepReadonly, readonly, Ref, ref, UnwrapRef } from 'vue'

type UseStateFunctionReturn<S> = [
    state: Readonly<Ref<DeepReadonly<UnwrapRef<S>>>>,
    setState: (newState: UnwrapRef<S>) => void
]

export function useState<S>(initialState: S): UseStateFunctionReturn<S> {
    const state = ref<typeof initialState>(initialState)
    function setState(newState: typeof state.value) {
        state.value = newState
    }

    return [readonly(state), setState]
}
