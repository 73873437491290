// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D4SDR-Toaster_root{position:absolute;left:50%;transform:translateX(-50%);position:fixed;top:1.6666666667rem;z-index:10000;text-align:center}.D4SDR-Toaster_root:hover .G_ohR-Toaster_toaster:nth-child(2) .ZtbcV-Toaster_body div,.D4SDR-Toaster_root:hover .G_ohR-Toaster_toaster:nth-child(3) .ZtbcV-Toaster_body div,.D4SDR-Toaster_root:hover .G_ohR-Toaster_toaster:nth-child(4) .ZtbcV-Toaster_body div{transition:.3s ease;transition-delay:.2s;opacity:1}.G_ohR-Toaster_toaster{position:absolute;left:50%;transform:translateX(-50%);transition:.3s ease;transition-property:transform,opacity,top,left}.G_ohR-Toaster_toaster:first-child{z-index:10003}.G_ohR-Toaster_toaster:nth-child(2){z-index:10002;transform:translateY(10%) translateX(-50%)}.G_ohR-Toaster_toaster:nth-child(2) .ZtbcV-Toaster_body div{transition:.1s ease;transition-property:opacity;opacity:0}.G_ohR-Toaster_toaster:nth-child(3){z-index:10001;transform:translateY(20%) translateX(-50%)}.G_ohR-Toaster_toaster:nth-child(3) .ZtbcV-Toaster_body div{transition:.1s ease;transition-property:opacity;opacity:0}.G_ohR-Toaster_toaster:nth-child(4){z-index:10000;transform:translateY(30%) translateX(-50%)}.G_ohR-Toaster_toaster:nth-child(4) .ZtbcV-Toaster_body div{transition:.1s ease;transition-property:opacity;opacity:0}.ZtbcV-Toaster_body{position:relative;z-index:2}.ZtbcV-Toaster_body,.UnTJW-Toaster_left,.TMEMS-Toaster_right{transition:.3s ease;transition-property:transform,opacity,top,left;background:#fff;box-sizing:border-box}.UnTJW-Toaster_left,.TMEMS-Toaster_right{position:absolute;top:0;z-index:1;height:100%;width:1rem}.UnTJW-Toaster_left{left:0;border-radius:1rem 0 0 1rem}.TMEMS-Toaster_right{right:0;border-radius:0 1rem 1rem 0}.NnyGT-Toaster_shadow{box-shadow:0 .1666666667rem .6666666667rem 0 rgba(33,36,44,.1);position:absolute;z-index:-1;background-color:transparent}.NnyGT-Toaster_shadow.ZtbcV-Toaster_body{width:100%;height:100%;top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "D4SDR-Toaster_root",
	"toaster": "G_ohR-Toaster_toaster",
	"body": "ZtbcV-Toaster_body",
	"left": "UnTJW-Toaster_left",
	"right": "TMEMS-Toaster_right",
	"shadow": "NnyGT-Toaster_shadow"
};
export default ___CSS_LOADER_EXPORT___;
