<template lang="pug">
article(
    :class="styles.root"
)
    div(
        :class="styles.head"
    )
        div(
            :class="styles.images"
        )
            image-svg-component(
                :class="styles.image"
                :src="images.tBank"
                width="20"
                height="20"
            )
            image-svg-component(
                :class="styles.image"
                :src="images.sbp"
                width="38.3"
                height="20"
            )
        div(
            :class="styles.images"
        )
            image-svg-component(
                :class="styles.image"
                :src="images.chip"
                width="36"
                height="28"
            )
    div(
        :class="styles.phone"
    )
        account-detail-component(
            :label="content.formPay.card.phone"
            :title="order.phone"
        )
    div(
        :class="styles.bottom"
    )
        div(
            :class="styles.detail"
        )
            account-detail-component(
                :label="content.formPay.card.bank"
                :title="order.recipient"
                :isCopy="false"
            )
        div(
            :class="styles.detail"
        )
            account-detail-component(
                :label="content.formPay.card.sum"
                :title="order.amount"
            )
    
</template>

<script lang="ts">
import Vue, { computed, useCssModule } from 'vue'
import { storeToRefs } from 'pinia'

import { getResources } from '@/packages/getResources'

import { useLanguage, useStore } from '@/entities/order/model'

import { AccountDetail } from '@/shared/ui/AccountDetail'
import { ImageSvg } from '@/shared/ui/ImageSvg'

export default Vue.extend({
    name: 'FormPayCard',
    setup() {
        const styles = useCssModule()
        const store = useLanguage()
        const { content } = storeToRefs(store)
        const { order } = useStore()

        const images = computed(() => {
            return {
                tBank: getResources(`images/card/TBank.svg`),
                sbp: getResources(`images/card/SBP.svg`),
                chip: getResources(`images/card/chip.svg`),
            }
        })

        return {
            styles,
            content,
            order,
            images,
        }
    },
    components: {
        'account-detail-component': AccountDetail,
        'image-svg-component': ImageSvg,
    },
})
</script>

<style module lang="scss" src="./Card.module.scss" />
