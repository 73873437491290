<template lang="pug">
button-base-component(
    :class="classes"
    :tag="tag"
    :type="type"
    :href="href"
    :isLoading="isLoading"
    :isDisabled="isDisabled"
    v-on="$listeners"
)
    template(
        #content=""
    )
        div(
            :class="styles.wrapper"
        )
            icon-base-component(
                v-if="iconLeft"
                :class="styles.icon"
                :name="iconLeft"
            )
            the-text-component(
                :class="styles.content"
                :name="fontName"
                color="inherit"
            )
                slot
            icon-base-component(
                v-if="iconRight"
                :class="styles.icon"
                :name="iconRight"
            )
            slot(
                name="after"
            )
    template(
        #loader=""
    )
        loader-component(
            size="l"
        )
</template>

<script lang="ts">
import Vue, { computed, toRefs } from 'vue'

import { Loader } from '@/shared/ui/Loader'
import { ButtonBase } from '@/shared/ui/ButtonBase'
import { type FontName, TheText } from '@/shared/ui/TheText'
import { IconBase } from '@/shared/ui/IconBase'

import styles from './Button.module.scss'
import { buttonProps } from './data'

export default Vue.extend({
    name: 'Button',
    props: buttonProps,
    components: {
        'button-base-component': ButtonBase,
        'icon-base-component': IconBase,
        'the-text-component': TheText,
        'loader-component': Loader,
    },
    setup(props) {
        const { view, size, isDisabled, iconLeft, iconRight } = toRefs(props)

        const classes = computed(() => [
            styles.root,
            styles[`view_${view.value}`],
            styles[`size_${size.value}`],
            isDisabled.value && styles['is-disabled'],
            iconLeft.value && styles[`is-icon-left`],
            iconRight.value && styles[`is-icon-right`],
        ])

        const fontName = computed<FontName>(() =>
            size.value === 'm' ? 'buttonM' : 'buttonL'
        )

        return {
            classes,
            fontName,
            styles,
        }
    },
})
</script>
