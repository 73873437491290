// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rhcXW-FormConfirm-module_title{margin-bottom:.6666666667rem}.LeuuI-FormConfirm-module_document,.amVtK-FormConfirm-module_input,.kkANb-FormConfirm-module_order-id{margin-bottom:2rem}.vunAn-FormConfirm-module_button-bottom{width:100%;margin-bottom:1.3333333333rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "rhcXW-FormConfirm-module_title",
	"document": "LeuuI-FormConfirm-module_document",
	"input": "amVtK-FormConfirm-module_input",
	"order-id": "kkANb-FormConfirm-module_order-id",
	"button-bottom": "vunAn-FormConfirm-module_button-bottom"
};
export default ___CSS_LOADER_EXPORT___;
