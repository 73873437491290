// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dudod-Input-module_input{height:4rem;padding:0 1.3333333333rem;border-radius:1rem;background-color:#e9ebed;color:rgba(0,0,0,.75);font-size:1.1666666667rem;line-height:1.6666666667rem;caret-color:#0a57f0;box-shadow:inset 0 0 0 1px transparent}.dudod-Input-module_input:-ms-input-placeholder{color:#737f8c;opacity:.75}.dudod-Input-module_input::placeholder{color:#737f8c;opacity:.75}@media(hover:hover),(min-width:1200px){.dudod-Input-module_input{transition:.3s ease;transition-property:color,box-shadow}.dudod-Input-module_input:-ms-input-placeholder{-ms-transition:opacity .3s ease;transition:opacity .3s ease}.dudod-Input-module_input::placeholder{transition:opacity .3s ease}.dudod-Input-module_input:hover{color:#000}.dudod-Input-module_input:hover:-ms-input-placeholder{opacity:1}.dudod-Input-module_input:hover::placeholder{opacity:1}}.KplZ0-Input-module_is-error{box-shadow:inset 0 0 0 1px #ff2b00;color:#ff2b00}.KplZ0-Input-module_is-error:-ms-input-placeholder{color:#ff2b00}.KplZ0-Input-module_is-error::placeholder{color:#ff2b00}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "dudod-Input-module_input",
	"is-error": "KplZ0-Input-module_is-error"
};
export default ___CSS_LOADER_EXPORT___;
