// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".voToi-CardInformer-module_root{background-color:rgba(245,102,0,.1);border-radius:1rem;padding:1.3333333333rem;text-align:left;display:block}.jgEZH-CardInformer-module_icon,.IdZll-CardInformer-module_text{display:inline-block;vertical-align:middle}.IdZll-CardInformer-module_text{margin-left:.8333333333rem;max-width:calc(100% - 2.16667rem)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "voToi-CardInformer-module_root",
	"icon": "jgEZH-CardInformer-module_icon",
	"text": "IdZll-CardInformer-module_text"
};
export default ___CSS_LOADER_EXPORT___;
