// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tv_8L-Link-module_root span{border-bottom:1px solid rgba(115,127,140,.5)}@media(hover:hover),(min-width:1200px){.tv_8L-Link-module_root:hover{color:#000}.tv_8L-Link-module_root:hover span{border-bottom:1px solid rgba(0,0,0,.5)}.tv_8L-Link-module_root span{transition:border-color .3s ease}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "tv_8L-Link-module_root"
};
export default ___CSS_LOADER_EXPORT___;
