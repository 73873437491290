<template lang="pug">
div(
    :class="[$style.root, $style[`view-${type}`], icon ? isIconLeft ? $style['is-icon-left'] : $style['is-icon-right'] : undefined]"
    
    v-on:click="$emit('click')"
)
    icon-base-component(
        v-if="icon"
        :class="$style.icon"
        :size="18"
        :name="icon"
        :color="'inherit'"
    )
    the-text-component(
        :class="$style.title"
        :color="'inherit'"
    ) {{ title }}
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { IconBase } from '@/shared/ui/IconBase'
import { TheText } from '@/shared/ui/TheText'
import { ToasterParams } from '@/shared/lib/hooks/useToaster'

export type ToasterItemProps = ToasterParams
export default Vue.extend({
    name: 'ToasterItem',
    props: {
        type: String as PropType<ToasterItemProps['type']>,
        icon: String as PropType<ToasterItemProps['icon']>,
        isIconLeft: {
            type: Boolean as PropType<ToasterItemProps['isIconLeft']>,
            default: true,
        },
        title: String as PropType<ToasterItemProps['title']>,
    },
    components: {
        'icon-base-component': IconBase,
        'the-text-component': TheText,
    },
})
</script>

<style lang="scss" module>
.root {
    position: relative;
    padding-top: rem(12);
    padding-bottom: rem(12);
    height: 100%;
    box-sizing: border-box;
}

.is-icon-left {
    padding-left: rem(26);

    .icon {
        left: rem(0);
    }
}

.is-icon-right {
    padding-right: rem(26);

    .icon {
        right: rem(0);
    }
}

.view {
    &-default {
        .icon {
            color: $textSecondary;
        }

        .title {
            color: $textPrimary;
        }
    }

    &-critical {
        .icon,
        .title {
            color: $textCritical;
        }
    }
}

.icon {
    position: absolute;
    top: rem(12);
}

.title {
    white-space: nowrap;
}
</style>
