// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".j_4kX-Button-module_root{transition:.3s ease;transition-property:color,background-color,opacity,transform,border-color;box-sizing:border-box;border-radius:1rem;min-width:15.1666666667rem}.nQLhZ-Button-module_is-disabled{opacity:.6}.HHZ5a-Button-module_view_primary{background-color:#000;color:#fff}@media(hover:hover),(min-width:1200px){.HHZ5a-Button-module_view_primary:hover{background-color:#1a1a1a}}.MIIbK-Button-module_view_actionFlat{color:#0a57f0}@media(hover:hover),(min-width:1200px){.MIIbK-Button-module_view_actionFlat:hover{color:#073eab}}.XBJVx-Button-module_view_secondary{color:#000;background-color:#f1f2f4}@media(hover:hover),(min-width:1200px){.XBJVx-Button-module_view_secondary:hover{background-color:#e9ebed}}.uVxIj-Button-module_size_l{padding:1.1666666667rem 1rem}.U_30y-Button-module_size_m{padding:.8333333333rem 1rem}.h7KOT-Button-module_icon{opacity:.8;font-size:1.6666666667rem;height:1.6666666667rem}.picTT-Button-module_content,.h7KOT-Button-module_icon{vertical-align:middle;display:inline-block}.Vhjae-Button-module_wrapper{font-size:0}.Vhjae-Button-module_wrapper>*{display:inline-block;vertical-align:middle}.Vhjae-Button-module_wrapper>*+*{margin-left:.3333333333rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "j_4kX-Button-module_root",
	"is-disabled": "nQLhZ-Button-module_is-disabled",
	"view_primary": "HHZ5a-Button-module_view_primary",
	"view_actionFlat": "MIIbK-Button-module_view_actionFlat",
	"view_secondary": "XBJVx-Button-module_view_secondary",
	"size_l": "uVxIj-Button-module_size_l",
	"size_m": "U_30y-Button-module_size_m",
	"icon": "h7KOT-Button-module_icon",
	"content": "picTT-Button-module_content",
	"wrapper": "Vhjae-Button-module_wrapper"
};
export default ___CSS_LOADER_EXPORT___;
