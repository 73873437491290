<template lang="pug">
text-component(
    tag="a"
    :class="styles.root"
    :href="href"
    rel="noopener noreferrer"
    target="_blank"
    color="secondary"
    name="captionRegular"
)
    span
        slot
</template>

<script lang="ts">
import Vue, { PropType, useCssModule } from 'vue'

import { TheText } from '@/shared/ui/TheText'

export type LinkProps = {
    href: string
}

export default Vue.extend({
    name: 'Link',
    props: {
        href: {
            type: String as PropType<LinkProps['href']>,
            required: true,
        },
    },
    setup() {
        const styles = useCssModule()

        return {
            styles,
        }
    },
    components: {
        'text-component': TheText,
    },
})
</script>

<style module lang="scss" src="./Link.module.scss" />
