import { Functional } from '../../../lib/vue-utils/functional-component'

export default Functional<{ appear?: boolean; duration?: number }>(
    (h, { children, props }) =>
        h(
            'transition',
            {
                props: {
                    name: 'fade',
                    mode: 'out-in',
                    appear: false,
                    duration: props.duration ?? 300,
                },
            },
            children
        )
)
