// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TuX3M-Card-module_root{border-radius:1rem;padding:1rem 1.3333333333rem;background-color:#212121}.IRaYY-Card-module_phone{padding:1rem 0}.BFuNh-Card-module_bottom{display:table;max-width:100%}.AGv8o-Card-module_detail{display:table-cell;width:100%;vertical-align:top}._OVhg-Card-module_image{display:inline-block;vertical-align:middle}._OVhg-Card-module_image+._OVhg-Card-module_image{margin-left:1rem}.qe44d-Card-module_chip{margin-left:auto}.M0U64-Card-module_head{display:table}._K6sN-Card-module_images{display:table-cell;vertical-align:middle;width:100%}._K6sN-Card-module_images+._K6sN-Card-module_images{padding-left:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "TuX3M-Card-module_root",
	"phone": "IRaYY-Card-module_phone",
	"bottom": "BFuNh-Card-module_bottom",
	"detail": "AGv8o-Card-module_detail",
	"image": "_OVhg-Card-module_image",
	"chip": "qe44d-Card-module_chip",
	"head": "M0U64-Card-module_head",
	"images": "_K6sN-Card-module_images"
};
export default ___CSS_LOADER_EXPORT___;
