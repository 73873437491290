import { createRequest } from '@/shared/api/create-request'

import { Api } from './types'

export const api: Api = {
    getPaymentOrder: orderId =>
        createRequest({
            url: `payments/${orderId}`,
            method: 'GET',
        }),
    sendContacts: data =>
        createRequest({
            url: `contacts`,
            method: 'POST',
            data,
        }),
}
