// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k6vMe-CardGuide-module_root{padding:1.3333333333rem 1.3333333333rem 0;background-color:#f1f2f4;border-radius:1rem;text-align:left}.EdqWc-CardGuide-module_is-bottom-space{padding-bottom:1.3333333333rem}.hrecG-CardGuide-module_label{margin-bottom:.1666666667rem}.iN3Id-CardGuide-module_image{margin-top:1.3333333333rem}.iN3Id-CardGuide-module_image img{width:100%;height:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "k6vMe-CardGuide-module_root",
	"is-bottom-space": "EdqWc-CardGuide-module_is-bottom-space",
	"label": "hrecG-CardGuide-module_label",
	"image": "iN3Id-CardGuide-module_image"
};
export default ___CSS_LOADER_EXPORT___;
