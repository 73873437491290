import { useLanguage } from '@/entities/order/model'

import { useToaster } from '@/shared/lib/hooks/useToaster'
import copyToBuffer from '@/shared/lib/utils/default/copyToBuffer'

export function useCopyText() {
    const { show } = useToaster()
    const store = useLanguage()
    function copy(text: string | number) {
        show(store.content.toaster.copy)
        copyToBuffer(text.toString())
    }
    return {
        copy,
    }
}
