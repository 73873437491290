<template lang="pug">
div(
    :class="classes" 
    data-testid="spinner"
)
    svg(
        v-bind="svgAttrs"
    )
        defs
            stop(
                offset="0%" 
                stop-opacity="0" 
                stop-color="currentColor"
            )
            stop(
                offset="100%"
                stop-opacity=".5"
                stop-color="currentColor"
            )
        linearGradient(
            :id="gradientsId.b"
        )
            stop( 
                offset="0%" 
                stop-opacity="0" 
                stop-color="currentColor"
            )
            stop(
                offset="100%"
                stop-opacity=".5"
                stop-color="currentColor"
            )
        g(
            stroke-width="15"
        )
            path(
                :stroke="`url(#${gradientsId.a})`"
                d="M15 100a85 85 0 0 1 170 0"
            )
            path(
                :stroke="`url(#${gradientsId.b})`"
                d="M185 100a85 85 0 0 1-170 0"
            )
</template>

<script lang="ts">
import Vue, { computed, PropType, useCssModule } from 'vue'

export type LoaderProps = {
    size: 'l' | 'xxl'
}

export default Vue.extend({
    name: 'Loader',
    props: {
        size: {
            type: String as PropType<LoaderProps['size']>,
            default: 'xxl',
        },
    },
    setup(props) {
        const styles = useCssModule()

        const gradientsId = {
            a: `spinner-gradient-a`,
            b: `spinner-gradient-b`,
        }

        const svgAttrs = computed(() => ({
            color: 'rgba(115, 127, 140, 1)',
            xmlns: 'http://www.w3.org/2000/svg',
            fill: 'none',
            viewBox: `0 0 200 200`,
            width: '200',
            height: '200',
        }))

        const classes = computed(() => {
            return [styles.root, styles[`size_${props.size}`]]
        })

        return {
            gradientsId,
            svgAttrs,
            styles,
            classes,
        }
    },
})
</script>

<style module lang="scss" src="./Loader.module.scss" />
