import { ref } from 'vue'

export type StatusFetch = Maybe<'loading' | 'success' | 'error' | 'paginating'>

export function useFetch<TResponse>(
    // eslint-disable-next-line
    fn: (params?: any) => TResponse,
    initStatus: StatusFetch = 'loading'
) {
    const status = ref<StatusFetch>(initStatus)

    function clearStatus() {
        status.value = initStatus
    }

    async function fetch() {
        status.value = 'loading'
        try {
            const result = await fn()
            //@ts-ignore
            if (result?.error && result.error.status) {
                //@ts-ignore
                throw new Error(JSON.stringify(result.error))
            }
            status.value = 'success'
            return result
        } catch (e) {
            status.value = 'error'
            throw new Error(e as string)
        }
    }

    return {
        fetch,
        status,
        clearStatus,
    }
}
