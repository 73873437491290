// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z5uGd-ToasterItem_root{position:relative;padding-top:1rem;padding-bottom:1rem;height:100%;box-sizing:border-box}.yanR7-ToasterItem_is-icon-left{padding-left:2.1666666667rem}.yanR7-ToasterItem_is-icon-left .XKmJD-ToasterItem_icon{left:0}._AloM-ToasterItem_is-icon-right{padding-right:2.1666666667rem}._AloM-ToasterItem_is-icon-right .XKmJD-ToasterItem_icon{right:0}.GRDYo-ToasterItem_view-default .XKmJD-ToasterItem_icon{color:#737f8c}.GRDYo-ToasterItem_view-default .aFo50-ToasterItem_title{color:#000}.ed3qg-ToasterItem_view-critical .XKmJD-ToasterItem_icon,.ed3qg-ToasterItem_view-critical .aFo50-ToasterItem_title{color:#ff2b00}.XKmJD-ToasterItem_icon{position:absolute;top:1rem}.aFo50-ToasterItem_title{white-space:nowrap}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Z5uGd-ToasterItem_root",
	"is-icon-left": "yanR7-ToasterItem_is-icon-left",
	"icon": "XKmJD-ToasterItem_icon",
	"is-icon-right": "_AloM-ToasterItem_is-icon-right",
	"view-default": "GRDYo-ToasterItem_view-default",
	"title": "aFo50-ToasterItem_title",
	"view-critical": "ed3qg-ToasterItem_view-critical"
};
export default ___CSS_LOADER_EXPORT___;
