export const {
    VUE_APP_TITLE,
    VUE_APP_API_DOMAIN,
    NODE_ENV,
    BASE_URL,
    VUE_APP_RESOURCES_DOMAIN,
} = process.env as Record<string, string>

export const env = Object.freeze({
    APP_TITLE: VUE_APP_TITLE,
    API_DOMAIN: VUE_APP_API_DOMAIN,
    RESOURCES_DOMAIN: VUE_APP_RESOURCES_DOMAIN,
    NODE_ENV,
    BASE_URL,
})

if (env.NODE_ENV === 'development' && console.table) {
    console.table(env)
}
