<template lang="pug">
dropzone-component(
    :class="styles.root"
    :status="dropZoneStatus"
    :isError="isError"
    @drop="onDrop"
)
    div(
        :class="styles.dropped"
    )
        icon-base-image-component(
            :class="styles.icon"
            name="icon-image_document_attachment"
            size="36"
        )
        div(
            :class="styles.information"
        )
            text-component(
                color="primary"
                name="captionRegular"
            ) {{ content.cardFile.dropped.text }}
            text-component(
                :class="styles['file-name']"
                color="secondary"
                name="captionRegular"
            ) {{ fileName }}
        button-icon-component(
            :class="styles['button-delete']"
            icon="icon-delete"
            view="critical-flat"
            @click="onDelete"
        )
    template(#placeholder="")
        div(
            :class="styles.placeholder"
        )
            button-component(
                :class="styles.button"
                view="secondary"
            ) {{ content.cardFile.placeholder.button }}
            text-component(
                :class="styles['text-placeholder']"
                color="secondary"
                name="captionRegular"
            ) {{ content.cardFile.placeholder.text }}
</template>

<script lang="ts">
import Vue, { computed, PropType, ref, useCssModule } from 'vue'
import { storeToRefs } from 'pinia'

import { useLanguage } from '@/entities/order/model'

import { Dropzone, DropzoneProps } from '@/shared/ui/Dropzone'
import { TheText } from '@/shared/ui/TheText'
import { Button } from '@/shared/ui/Button'
import { IconBaseImage } from '@/shared/ui/IconBaseImage'
import { ButtonIcon } from '@/shared/ui/ButtonIcon'

export type CardFileProps = Pick<DropzoneProps, 'isError'> & {
    value?: Maybe<File>
}

export type CardFileEmits = {
    (event: 'input', value: CardFileProps['value']): void
    (event: 'update:isError', value: boolean): void
}

export default Vue.extend({
    name: 'CardFile',
    props: {
        value: {
            type: [File, null] as PropType<CardFileProps['value']>,
        },
        isError: {
            type: Boolean as PropType<CardFileProps['isError']>,
        },
    },
    setup(props, { emit }: { emit: CardFileEmits }) {
        const styles = useCssModule()
        const storeLanguage = useLanguage()
        const { content } = storeToRefs(storeLanguage)

        const fileName = ref()
        const dropZoneStatus = computed<DropzoneProps['status']>(() => {
            return props.value ? 'dropped' : 'default'
        })

        async function onDrop(file: File) {
            fileName.value = file.name
            emit('input', file)
            emit('update:isError', false)
        }

        function onDelete() {
            emit('input', null)
        }

        return {
            styles,
            dropZoneStatus,
            onDrop,
            onDelete,
            fileName,
            content,
        }
    },
    components: {
        'dropzone-component': Dropzone,
        'text-component': TheText,
        'button-component': Button,
        'icon-base-image-component': IconBaseImage,
        'button-icon-component': ButtonIcon,
    },
})
</script>

<style module lang="scss" src="./CardFile.module.scss" />
