<template lang="pug">
div(
    :class="styles.root"
)
    loader-component(
        v-if="isLoading"
        :class="styles.loader"
    )
    icon-base-image-component(
        v-else-if="iconName"
        :class="styles.icon"
        :name="iconName"
        size="80"
    )
    text-component(
        :class="styles.title"
        color="primary"
        name="titleSmallSemibold"
    ) {{ title }}
    text-component(
        v-if="subtitle"
        :class="styles.subtitle"
        name="captionRegular"
        color="primary"
    ) {{ subtitle }}
    text-component(
        v-if="text"
        :class="styles.text"
        :isAnimated="false"
        name="captionRegular"
        color="secondary"
    ) {{ text }}
    text-component(
        v-if="description"
        :class="styles.description"
        name="captionRegular"
        color="primary"
    ) {{ description }}
    button-component(
        v-if="isAction && actionText"
        :class="styles.button"
        view="secondary"
        size="l"
        @click="$emit('click')"
    ) {{ actionText }}
</template>

<script lang="ts">
import Vue, { computed, PropType, useCssModule } from 'vue'

import { Button } from '@/shared/ui/Button'
import { TheText } from '@/shared/ui/TheText'
import { IconBaseImage, IconBaseImageProps } from '@/shared/ui/IconBaseImage'
import { Loader } from '@/shared/ui/Loader'

export type ResponseProps = {
    view: 'success' | 'error' | 'icon'
    title: string
    text?: string
    actionText?: string
    description?: string
    subtitle?: string
    isAction?: boolean
    isLoading?: boolean
}

export default Vue.extend({
    name: 'Response',
    props: {
        title: {
            type: String as PropType<ResponseProps['title']>,
            required: true,
        },
        text: {
            type: String as PropType<ResponseProps['text']>,
        },
        view: {
            type: String as PropType<ResponseProps['view']>,
        },
        subtitle: {
            type: String as PropType<ResponseProps['subtitle']>,
        },
        description: {
            type: String as PropType<ResponseProps['description']>,
        },
        actionText: {
            type: String as PropType<ResponseProps['actionText']>,
        },
        isAction: {
            type: Boolean as PropType<ResponseProps['isAction']>,
        },
        isLoading: {
            type: Boolean as PropType<ResponseProps['isLoading']>,
        },
    },
    setup(props) {
        const styles = useCssModule()
        const iconName = computed<Maybe<IconBaseImageProps['name']>>(() => {
            switch (props.view) {
                case 'success':
                    return 'icon-image_check_filled'
                case 'error':
                    return 'icon-image_alert_filled'
                case 'icon':
                    return 'icon-image_mail_success'
                default:
                    return null
            }
        })

        return {
            styles,
            iconName,
        }
    },

    components: {
        'button-component': Button,
        'text-component': TheText,
        'icon-base-image-component': IconBaseImage,
        'loader-component': Loader,
    },
})
</script>

<style module lang="scss" src="./Response.module.scss" />
