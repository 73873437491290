// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NwEHi-FAQ-module_buttonBottom,.jX59X-FAQ-module_buttonTop{margin-bottom:.3333333333rem;width:100%}.PKYwM-FAQ-module_title{margin-bottom:2rem}.qPdu_-FAQ-module_card{margin-bottom:1rem}.rQaok-FAQ-module_information{margin-bottom:2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonBottom": "NwEHi-FAQ-module_buttonBottom",
	"buttonTop": "jX59X-FAQ-module_buttonTop",
	"title": "PKYwM-FAQ-module_title",
	"card": "qPdu_-FAQ-module_card",
	"information": "rQaok-FAQ-module_information"
};
export default ___CSS_LOADER_EXPORT___;
