<template lang="pug">
    div(
        ref="rootRef"
        :class="$style.root"

        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave"
    )
        transition-group(
            name="toaster"
            tag="div"
        )
            template(
                v-for="(item, key) in list"
            )
                div(
                    :id="item.id"
                    :key="item.id"
                    ref="toastersRef"
                    :class="[$style.toaster, isExpand ? 'is-expand' : '']"
                    :style="getStylesByIndex(key).toaster"
                )
                    div(
                        :class="$style.left"
                        :style="getStylesByIndex(key).left"
                    )
                    div(
                        :class="$style.body"
                        :style="getStylesByIndex(key).body"
                    )
                        toaster-item-component(
                            v-bind="item"

                            @click="() => onRemove(item.id)"
                        )
                    div(
                        :class="$style.right"
                        :style="getStylesByIndex(key).right"
                    )
                    div(
                        :class="[$style.left, $style.shadow]"
                        :style="getStylesByIndex(key).left"
                    )
                    div(
                        :class="[$style.body, $style.shadow]"
                        :style="getStylesByIndex(key).body"
                    )
                    div(
                        :class="[$style.right, $style.shadow]"
                        :style="getStylesByIndex(key).right"
                    )
</template>

<script lang="ts">
import Vue, { nextTick, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

import { useToasterSettings } from '@/shared/lib/hooks/useToaster'

import ToasterItem from './ToasterItem.vue'

type ElementKey = 'main' | 'second' | 'third'

type CountedStyles = {
    mainTranslate: string
    scale: string
    leftTranslate: string
    rightTranslate: string
}

export default Vue.extend({
    name: 'Toaster',
    setup() {
        const store = useToasterSettings()
        const { list } = storeToRefs(store)

        const rootRef = ref<HTMLDivElement>()

        const toastersRef = ref<HTMLDivElement[]>([])
        const bodiesRef = ref<HTMLDivElement[]>([])
        const leftsRef = ref<HTMLDivElement[]>([])
        const rightsRef = ref<HTMLDivElement[]>([])

        const isExpand = ref(false)

        const toasterTransform = ref(generateTransformStyles())
        const bodiesTransform = ref(generateTransformStyles())
        const leftTransform = ref(generateTransformStyles())
        const rightTransform = ref(generateTransformStyles())

        function getStylesByIndex(index: number) {
            const key = indexToElementKey(index)

            return {
                toaster: {
                    transform: toasterTransform.value[key],
                },
                body: {
                    transform: bodiesTransform.value[key],
                },
                left: {
                    transform: leftTransform.value[key],
                },
                right: {
                    transform: rightTransform.value[key],
                },
            }
        }

        function setStylesByKey(styles: CountedStyles, key: ElementKey) {
            if (key !== 'main' || !!toasterTransform.value.main.length) {
                toasterTransform.value[key] = styles.mainTranslate
            }
            bodiesTransform.value[key] = styles.scale
            leftTransform.value[key] = styles.leftTranslate
            rightTransform.value[key] = styles.rightTranslate
        }

        function setExpandStyles() {
            const OFFSET = 12
            let overHeight = 0
            let maxWidth = 0

            toastersRef.value.sort(compareDivById).forEach((item, index) => {
                const key = indexToElementKey(index)
                const styles = getInitialStyles(`${overHeight}px`)
                setStylesByKey(styles, key)

                overHeight += item.clientHeight + OFFSET
                maxWidth = Math.max(maxWidth, item.clientWidth)
            })

            rootRef.value!.style.height = `${overHeight}px`
            rootRef.value!.style.width = `${maxWidth}px`
        }

        function setToastsOverlayStyles() {
            nextTick(() => {
                const [main, second, third] =
                    toastersRef.value.sort(compareDivById)

                const mainStyles = getInitialStyles('0')
                setStylesByKey(mainStyles, 'main')

                if (second) {
                    const secondStyles = countStyles({
                        mainElement: main,
                        element: second,
                        translateY: '15%',
                        index: 1,
                    })
                    setStylesByKey(secondStyles, 'second')
                }

                if (third) {
                    const thirdStyles = countStyles({
                        mainElement: main,
                        element: third,
                        translateY: '30%',
                        index: 2,
                    })
                    setStylesByKey(thirdStyles, 'third')

                    nextTick(() => {
                        const leaveElement = rootRef.value?.querySelector(
                            '.toaster-leave-active'
                        )

                        if (leaveElement) {
                            const leaveStyles = countStyles({
                                mainElement: main,
                                element: leaveElement,
                                translateY: '45%',
                                index: 3,
                            })

                            leaveElement.setAttribute(
                                'style',
                                `transform: ${leaveStyles.mainTranslate};`
                            )
                            leaveElement.children[1].setAttribute(
                                'style',
                                `transform: ${leaveStyles.scale};`
                            )
                            leaveElement.children[0].setAttribute(
                                'style',
                                `transform: ${leaveStyles.leftTranslate};`
                            )
                            leaveElement.children[2].setAttribute(
                                'style',
                                `transform: ${leaveStyles.rightTranslate};`
                            )
                            leaveElement.children[4].setAttribute(
                                'style',
                                `transform: ${leaveStyles.scale};`
                            )
                            leaveElement.children[3].setAttribute(
                                'style',
                                `transform: ${leaveStyles.leftTranslate};`
                            )
                            leaveElement.children[5].setAttribute(
                                'style',
                                `transform: ${leaveStyles.rightTranslate};`
                            )
                        }
                    })
                }
            })
        }

        function onRemove(id: number) {
            store.remove(id)
            setExpandStyles()
        }

        function onMouseOver() {
            isExpand.value = true
            store.pauseTimeouts()
            setExpandStyles()
        }

        function onMouseLeave() {
            isExpand.value = false
            setToastsOverlayStyles()
            rootRef.value!.style.height = `auto`
            rootRef.value!.style.width = `auto`
            store.restartTimeouts()
        }

        watch(
            list,
            () => {
                if (isExpand.value) {
                    setExpandStyles()
                } else {
                    setToastsOverlayStyles()
                }
            },
            { deep: true }
        )

        return {
            rootRef,
            toastersRef,
            bodiesRef,
            leftsRef,
            rightsRef,

            list,

            isExpand,

            getStylesByIndex,

            onMouseOver,
            onMouseLeave,
            onRemove,
        }
    },
    components: {
        'toaster-item-component': ToasterItem,
    },
})

function generateTransformStyles(): Record<ElementKey, string> {
    return {
        main: '',
        second: '',
        third: '',
    }
}

function indexToElementKey(index: number): ElementKey {
    switch (index) {
        case 0:
            return 'main'
        case 1:
            return 'second'
        default:
            return 'third'
    }
}

function compareDivById(a: HTMLDivElement, b: HTMLDivElement) {
    return Number(b.id) - Number(a.id)
}

function countStyles({
    mainElement,
    element,
    translateY,
    index,
}: {
    mainElement: HTMLDivElement
    element: HTMLDivElement | Element
    translateY: string
    index: number
}): CountedStyles {
    const rect = element.getBoundingClientRect()
    const mainRect = mainElement.getBoundingClientRect()

    const heightScale = mainRect.height / rect.height
    const widthScale = (mainRect.width - 10 * index) / rect.width
    const widthDiff = (rect.width - widthScale * rect.width) / 2

    return {
        mainTranslate: `translateY(${translateY}) translateX(-50%)`,
        scale: `scale(${widthScale}, ${heightScale})`,
        leftTranslate: `translateX(${widthDiff - 12}px)`,
        rightTranslate: `translateX(${-widthDiff + 12}px)`,
    }
}

function getInitialStyles(translateY: string): CountedStyles {
    return {
        mainTranslate: `translateY(${translateY}) translateX(-50%)`,
        scale: `scale(1, 1)`,
        leftTranslate: 'translateX(-12px)',
        rightTranslate: 'translateX(12px)',
    }
}
</script>

<style lang="scss" module>
.root {
    @include center(horizontal);
    position: fixed;
    top: rem(20);
    z-index: $zIndexToaster;
    text-align: center;

    &:hover {
        .toaster {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                .body {
                    div {
                        transition: 0.3s ease;
                        transition-delay: 0.2s;

                        opacity: 1;
                    }
                }
            }
        }
    }
}

.toaster {
    @include center(horizontal);
    transition: 0.3s ease;
    transition-property: transform, opacity, top, left;

    &:nth-child(1) {
        z-index: $zIndexToaster + 3;
    }

    @for $i from 2 through 4 {
        &:nth-child(#{$i}) {
            z-index: $zIndexToaster + (4 - $i);
            transform: translateY(#{10% + 10% * ($i - 2)}) translateX(-50%);

            .body {
                div {
                    transition: 0.1s ease;
                    transition-property: opacity;

                    opacity: 0;
                }
            }
        }
    }
}

.body {
    position: relative;
    z-index: 2;
}

.left,
.right,
.body {
    transition: 0.3s ease;
    transition-property: transform, opacity, top, left;
    background: #fff;
    box-sizing: border-box;
}

.left,
.right {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    width: rem(12);
}

.left {
    left: 0;
    border-radius: rem(12) 0 0 rem(12);
}

.right {
    right: 0;
    border-radius: 0 rem(12) rem(12) 0;
}

.shadow {
    box-shadow: 0 rem(2) rem(8) 0 rgba(33, 36, 44, 0.1);
    position: absolute;
    z-index: -1;
    background-color: transparent;

    &.body {
        width: 100%;
        height: 100%;
        top: 0;
    }
}
</style>
