<template lang="pug">
response-component(
    view="success"
    :title="content.success.title"
    :text="`${content.success.orderId} ${store.order.id}`"
    :description="`${content.success.amount} ${store.order.amount}`"
)
</template>

<script lang="ts">
import Vue from 'vue'
import { storeToRefs } from 'pinia'

import { useLanguage, useStore } from '@/entities/order/model'

import { Response } from '@/shared/ui/Response'

export default Vue.extend({
    name: 'OrderSuccess',
    setup() {
        const storeLanguage = useLanguage()
        const store = useStore()
        const { content } = storeToRefs(storeLanguage)

        return {
            content,
            store,
        }
    },
    components: {
        'response-component': Response,
    },
})
</script>
