import { VNode } from 'vue'

type LazyNode = {
    data: {
        attrs: {
            'data-src': string
        }
    }
}

export default {
    bind(el: HTMLElement, _: unknown, vnode: VNode) {
        if (vnode.tag !== 'img') {
            throw new Error(
                `${vnode.tag} is wrong element, use img element instead`
            )
        }

        el.classList.add('lazyload')
    },
    update(el: HTMLElement, _: unknown, vnode: VNode, oldVnode: VNode) {
        const {
            data: {
                attrs: { 'data-src': src },
            },
        } = vnode as VNode & LazyNode
        const {
            data: {
                attrs: { 'data-src': oldSrc },
            },
        } = oldVnode as VNode & LazyNode

        if (src === oldSrc) {
            return
        }

        el.classList.remove('lazyloaded')
        el.classList.add('lazyload')
    },
}
