// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZHRVP-Dropzone-module_card{transition:.3s ease;transition-property:background-color,border-color;position:relative;overflow:hidden;border:1px dashed #d4d7dd;border-radius:1rem;padding:1.3333333333rem}.ZHRVP-Dropzone-module_card.rL4nV-Dropzone-module_is-dropping{background-color:rgba(10,87,240,.1);border-color:#0a57f0}.ZHRVP-Dropzone-module_card.XsfaV-Dropzone-module_is-dropped{border-color:#d4d7dd;border-style:solid}.ZHRVP-Dropzone-module_card.GK6TJ-Dropzone-module_is-error{border-color:#ff2b00}.g2OH0-Dropzone-module_input{opacity:0;position:absolute;top:0;left:0;width:100%;height:100%;cursor:pointer;z-index:1}.aSbqC-Dropzone-module_input-hidden{visibility:hidden}.tRuc2-Dropzone-module_no-drop{pointer-events:none}.gHBy4-Dropzone-module_content{transition:opacity .3s ease}.WsQGk-Dropzone-module_dropping{text-align:center;margin:0 auto}.WsQGk-Dropzone-module_dropping i{margin-bottom:.6666666667rem}.AbDrW-Dropzone-module_error-text{margin-top:.6666666667rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "ZHRVP-Dropzone-module_card",
	"is-dropping": "rL4nV-Dropzone-module_is-dropping",
	"is-dropped": "XsfaV-Dropzone-module_is-dropped",
	"is-error": "GK6TJ-Dropzone-module_is-error",
	"input": "g2OH0-Dropzone-module_input",
	"input-hidden": "aSbqC-Dropzone-module_input-hidden",
	"no-drop": "tRuc2-Dropzone-module_no-drop",
	"content": "gHBy4-Dropzone-module_content",
	"dropping": "WsQGk-Dropzone-module_dropping",
	"error-text": "AbDrW-Dropzone-module_error-text"
};
export default ___CSS_LOADER_EXPORT___;
