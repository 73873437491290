<template lang="pug">
div(
    :class="styles.root"
)
    button-component(
        :class="styles.buttonTop"
        size="m"
        iconLeft="icon-arrow_left"
        view="actionFlat"
        @click="onClick"
    ) {{ content.faq.buttonTop }}
    text-component(
        :class="styles.title"
        name="titleSmallSemibold"
        color="primary"
    ) {{ content.faq.title }}
    card-guide-component(
        v-for="(card, index) in content.faq.cards"
        :class="styles.card"
        :key="index"
        :title="card"
        :label="`${content.faq.cardLabel} ${index + 1}`"
        :image="index <= 5 ? index + 1 :  null"
    )
    card-informer-component(
        :class="styles.information"
    ) {{ content.faq.information }}
    button-component(
        :class="styles.buttonBottom"
        size="l"
        view="primary"
        @click="onClick"
    ) {{ content.faq.buttonBottom }}
</template>

<script lang="ts">
import Vue, { useCssModule } from 'vue'
import { storeToRefs } from 'pinia'

import { Step, useLanguage, useStore } from '@/entities/order/model'

import { TheText } from '@/shared/ui/TheText'
import { Button } from '@/shared/ui/Button'
import { CardGuide } from '@/shared/ui/CardGuide'
import { CardInformer } from '@/shared/ui/CardInformer'

export default Vue.extend({
    name: 'OrderFAQ',
    setup() {
        const styles = useCssModule()
        const store = useLanguage()
        const { content } = storeToRefs(store)
        const { setStep } = useStore()

        function onClick() {
            setStep(Step.FORM_PAY)
        }

        return {
            styles,
            content,
            onClick,
        }
    },
    components: {
        'text-component': TheText,
        'button-component': Button,
        'card-guide-component': CardGuide,
        'card-informer-component': CardInformer,
    },
})
</script>

<style module lang="scss" src="./FAQ.module.scss" />
