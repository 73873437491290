<template lang="pug">
div(
    id="app"
)
    order-layout-component
    toaster-component
</template>

<script lang="ts">
import Vue, { useCssModule } from 'vue'

import { Toaster } from '@/app/toaser'

import { Layout } from '@/entities/order/ui/Layout'

export default Vue.extend({
    name: 'App',
    setup() {
        const styles = useCssModule()
        return {
            styles,
        }
    },
    components: {
        'order-layout-component': Layout,
        'toaster-component': Toaster,
    },
})
</script>

<style lang="scss" src="./app.scss" />
<style module lang="scss" src="./app-module.scss" />
