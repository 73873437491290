<template lang="pug">
    div(
        :class="styles.root"
        v-on:click="onClick"
    )
        div(
            :class="styles.caret"
            :style="caretStyles"
        )
        button(
            v-for="(segment, index) in list"
            :class="styles.segment"
            :key="segment.id"
            ref="refItems"
        ) 
            text-component(
                name="captionMiniMedium"
                :color="segment.id === value ? 'primary' : 'secondary'"
                :isAnimated="false"
            ) {{ segment.text }}
</template>

<script lang="ts">
import Vue, {
    computed,
    onMounted,
    onUnmounted,
    PropType,
    ref,
    useCssModule,
    watch,
} from 'vue'

import { TheText } from '@/shared/ui/TheText'
import { useState } from '@/shared/lib/hooks/useState'

type Segment = {
    id: number
    text: string
}

export type SegmentControlProps = {
    value: Segment['id']
    list: Segment[]
}

export type SegmentControlEmits = {
    (event: 'input', value: Segment['id']): void
}

let timeoutInit: ReturnType<typeof setTimeout>
let timeoutMounted: ReturnType<typeof setTimeout>

export default Vue.extend({
    name: 'SegmentControl',
    props: {
        value: {
            type: Number as PropType<SegmentControlProps['value']>,
            required: true,
        },
        list: {
            type: Array as PropType<SegmentControlProps['list']>,
            required: true,
        },
    },
    setup(props, { emit }: { emit: SegmentControlEmits }) {
        const styles = useCssModule()
        const refItems = ref<HTMLButtonElement[]>([])

        const [caretWidth, setCaretWidth] = useState<Maybe<number>>(null)
        const [caretLeft, setCaretLeft] = useState<Maybe<number>>(null)

        const [isMounted, setIsMounted] = useState(false)

        const caretStyles = computed(() => {
            return {
                width: caretWidth.value ? `${caretWidth.value}px` : null,
                transform: caretLeft.value
                    ? `translateX(${caretLeft.value}px)`
                    : null,
                ...(!isMounted.value && {
                    transition: 'none',
                }),
            }
        })

        function countCaretOptions(index: number) {
            if (index !== -1) {
                const activeElement = refItems.value[index]
                const { width } = activeElement.getBoundingClientRect()
                const left = activeElement.offsetLeft

                setCaretWidth(width)
                setCaretLeft(left)
            }
        }

        function onClick() {
            const newValueIndex = props.value === 1 ? 1 : 0
            const newValue = props.list[newValueIndex].id

            emit('input', newValue)
        }

        function initCaretOptions() {
            const activeIndex = props.list?.findIndex(
                item => item.id === props.value
            )
            countCaretOptions(activeIndex)
        }

        function onResize() {
            initCaretOptions()
        }

        function onCreated() {
            window.addEventListener('resize', onResize, { passive: true })
        }

        onCreated()
        onMounted(() => {
            timeoutInit = setTimeout(() => {
                initCaretOptions()
            }, 50)

            timeoutMounted = setTimeout(() => {
                setIsMounted(true)
            }, 100)
        })
        onUnmounted(() => {
            window.removeEventListener('resize', onResize)
            clearTimeout(timeoutMounted)
            clearTimeout(timeoutInit)
        })

        watch(
            () => props.value,
            newValue => {
                const activeIndex = props.list.findIndex(
                    item => item.id === newValue
                )
                countCaretOptions(activeIndex)
            }
        )

        return {
            styles,
            onClick,
            caretStyles,
            refItems,
        }
    },
    components: {
        'text-component': TheText,
    },
})
</script>

<style module lang="scss" src="./SegmentControl.module.scss" />
