import { PropType } from 'vue'

import { ButtonBaseProps } from '@/shared/ui/ButtonBase'
import { ButtonProps } from '@/shared/ui/Button/model'

export const buttonProps = {
    tag: {
        type: String as PropType<ButtonBaseProps['tag']>,
        default: 'button',
    },
    type: {
        type: String as PropType<ButtonBaseProps['type']>,
        default: 'button',
    },
    href: {
        type: String as PropType<ButtonBaseProps['href']>,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    isDisabled: {
        type: Boolean,
        default: false,
    },
    view: {
        type: String as PropType<ButtonProps['view']>,
        default: 'primary',
    },
    size: {
        type: String as PropType<ButtonProps['size']>,
        default: 'm',
    },
    iconLeft: {
        type: String as PropType<ButtonProps['iconLeft']>,
        default: undefined,
    },
    iconRight: {
        type: String as PropType<ButtonProps['iconLeft']>,
        default: undefined,
    },
}
