<template lang="pug">
div(
    :class="styles.root"
)
    text-component(
        :class="styles.title"
        name="titleSmallSemibold"
        color="primary"
    ) {{ content.formConfirm.title }}
    text-component(
        :class="styles['order-id']"
        :isAnimated="false"
        name="captionRegular"
        color="secondary"
    ) {{ `${content.formConfirm.orderId} ${store.order.id}` }} 
    input-component(
        v-model="storeForm.email"
        :isError.sync="storeForm.errors.email"
        :class="styles.input"
        :placeholder="content.formConfirm.inputPlaceholder"
    )
    card-file-component(
        v-model="storeForm.document"
        :isError.sync="storeForm.errors.document"
        :class="styles.document"
    )
    button-action-component(
        :class="styles['button-bottom']"
        :action="storeForm.send"
        size="l"
        view="primary"
    ) {{ content.formConfirm.buttonBottom }}
    text-component(
        name="captionRegular"
        color="secondary"
    ) {{ `${content.formConfirm.terms} ` }}
        link-component(
            href="/agreement"
        ) {{ content.formConfirm.termsLink }}
</template>

<script lang="ts">
import Vue, { onUnmounted, useCssModule } from 'vue'
import { storeToRefs } from 'pinia'

import {
    Step,
    useFormConfirm,
    useLanguage,
    useStore,
} from '@/entities/order/model'

import { TheText } from '@/shared/ui/TheText'
import { Button } from '@/shared/ui/Button'
import { CardFile } from '@/shared/ui/CardFile'
import { Link } from '@/shared/ui/Link'
import { Input } from '@/shared/ui/Input'
import { ButtonAction } from '@/shared/ui/ButtonAction'

export default Vue.extend({
    name: 'OrderFormConfirm',
    setup() {
        const styles = useCssModule()
        const storeForm = useFormConfirm()
        const storeLanguage = useLanguage()
        const store = useStore()
        const { content } = storeToRefs(storeLanguage)

        onUnmounted(storeForm.$reset)

        return {
            styles,
            store,
            content,
            storeForm,
        }
    },
    components: {
        'text-component': TheText,
        'button-component': Button,
        'button-action-component': ButtonAction,
        'card-file-component': CardFile,
        'link-component': Link,
        'input-component': Input,
    },
})
</script>

<style module lang="scss" src="./FormConfirm.module.scss" />
