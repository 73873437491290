// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GQI0t-IconBase-module_root{display:inline-block;font-size:inherit}.GQI0t-IconBase-module_root:before{display:block}.EfsxC-IconBase-module_is-clickable{cursor:pointer}.gRyWI-IconBase-module_color_primary{color:#000}.CLmG2-IconBase-module_color_warning{color:#f56600}.JwvVb-IconBase-module_color_action{color:#0a57f0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "GQI0t-IconBase-module_root",
	"is-clickable": "EfsxC-IconBase-module_is-clickable",
	"color_primary": "gRyWI-IconBase-module_color_primary",
	"color_warning": "CLmG2-IconBase-module_color_warning",
	"color_action": "JwvVb-IconBase-module_color_action"
};
export default ___CSS_LOADER_EXPORT___;
