import { render, staticRenderFns } from "./ErrorId.vue?vue&type=template&id=1a35d6fd&lang=pug"
import script from "./ErrorId.vue?vue&type=script&lang=ts"
export * from "./ErrorId.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports