import { CreateElement, RenderContext, VNode } from 'vue'

// eslint-disable-next-line
type FunctionalComponent = any

export function Functional<Props>(
    renderFn: (h: CreateElement, context: RenderContext<Props>) => VNode | null
): FunctionalComponent {
    return {
        functional: true,
        render(h: CreateElement, context: RenderContext<Props>) {
            return renderFn(h, context)
        },
    }
}
