<template lang="pug">
div(
    :class="styles.root"
)
    div(
        :class="cardClasses"
    )
        input(
            title="Добавить файл"
            ref="refInput"
            type="file"
            :class="inputClasses"
            :accept="acceptFormats"
            v-on:dragenter="onDragEnter"
            v-on:dragleave="onDragLeave"
            v-on:input="onInput"
        )
        div(
            ref="refContent"
            :class="styles.content"
        )
            transition-fade-component
                div(
                    v-if="isDropping"
                    key="dropping"
                    :class="styles.dropping"
                )
                    icon-base-component(
                        color="action"
                        size="24"
                        name="icon-document_attachment"
                    )
                    text-component(
                        color="action"
                        name="captionRegular"
                    ) {{ content.dropzone.text }}
                div(
                    v-else-if="status === 'dropped'"
                    key="dropped"
                )
                    slot
                div( 
                    v-else
                    key="default"
                )
                    slot(
                        name="placeholder"
                    )
    transition-fade-component
        text-component(
            v-if="errorTextLocal"
            :class="styles['error-text']"
            color="critical"
            name="captionRegular"
        ) {{ errorTextLocal }}
</template>

<script lang="ts">
import Vue, { computed, PropType, ref, useCssModule } from 'vue'
import { storeToRefs } from 'pinia'

import { useLanguage } from '@/entities/order/model'

import { useState } from '@/shared/lib/hooks/useState'
import { IconBase } from '@/shared/ui/IconBase'
import { TheText } from '@/shared/ui/TheText'
import { TransitionFade } from '@/shared/ui/transitions/TransitionFade'

export type DropzoneProps = {
    status: 'default' | 'dropped'
    isError?: boolean
    maxSize?: string
    formats?: string[]
}
export type DropzoneEmits = {
    (event: 'drop', file: File): void
}

export default Vue.extend({
    name: 'Dropzone',
    props: {
        status: {
            type: String as PropType<DropzoneProps['status']>,
            required: true,
        },
        maxSize: {
            type: String as PropType<DropzoneProps['maxSize']>,
            default: '5MB',
        },
        formats: {
            type: Array as PropType<DropzoneProps['formats']>,
            default: () => ['png', 'jpg', 'doc', 'docx', 'pdf'],
        },
        isError: {
            type: Boolean as PropType<DropzoneProps['isError']>,
        },
    },
    setup(props, { emit }: { emit: DropzoneEmits }) {
        const styles = useCssModule()
        const [isDropping, setIsDropping] = useState(false)
        const refInput = ref<HTMLInputElement>()
        const refContent = ref<HTMLDivElement>()

        const storeLanguage = useLanguage()
        const { content } = storeToRefs(storeLanguage)

        const [isErrorSize, setIsErrorSize] = useState(false)
        const [isErrorFormats, setIsErrorFormats] = useState(false)
        const [file, setFile] = useState<Maybe<File>>(null)

        function onInput(e: InputEvent) {
            const { files } = e.target as HTMLInputElement

            if (refInput.value && files?.length) {
                const [inputFile] = files

                setFile(inputFile)

                const isValidSize = validateSize(inputFile.size)
                const isValidFormats = validateFormats(inputFile.name)

                setIsErrorSize(!isValidSize)
                setIsErrorFormats(!isValidFormats)

                if (isValidSize && isValidFormats) {
                    emit('drop', file.value!)
                }

                refInput.value.value = ''
                setFile(null)
            } else {
                setIsErrorSize(false)
                setIsErrorFormats(false)
            }

            setIsDropping(false)
        }

        function onDragEnter() {
            if (props.status === 'default') {
                setIsDropping(true)
            }
        }

        function onDragLeave() {
            if (props.status === 'default') {
                setIsDropping(false)
            }
        }

        function validateSize(fileSize: number) {
            const isValidProp = /^\d+(mb|kb|MB|KB|b|B)?$/.test(
                props.maxSize ?? ''
            )

            if (!isValidProp) {
                return true
            }

            const { size } = getMaxSizeOptions()

            return fileSize <= Number(size)
        }

        function validateFormats(fileName: string) {
            if (!props.formats?.length) {
                return true
            }

            const extension = fileName.split('.').pop()!
            return props.formats.includes(extension)
        }

        function getMaxSizeOptions() {
            const match = props.maxSize!.match(/^(\d+)(mb|kb|MB|KB|b|B)?$/)!
            const unit = match[2].toLowerCase() as 'b' | 'kb' | 'mb'
            const size = Number(match[1])
            let fileSize = size

            switch (unit) {
                case 'kb':
                    fileSize = size * 1024
                    break
                case 'mb':
                    fileSize = size * 1024 * 1024
                    break
                default:
                    break
            }

            return {
                size: fileSize,
                sizeText: size,
                unit,
            }
        }

        const errorTextLocal = computed(() => {
            if (isErrorFormats.value) {
                return 'Неверный формат файла'
            }

            if (isErrorSize.value) {
                const { unit, sizeText } = getMaxSizeOptions()
                let unitToRus = ''

                switch (unit) {
                    case 'b':
                        unitToRus = 'Б'
                        break
                    case 'kb':
                        unitToRus = 'КБ'
                        break
                    case 'mb':
                        unitToRus = 'МБ'
                        break
                    default:
                        break
                }

                return `Максимальный размер файла – ${sizeText} ${unitToRus}`
            }

            return ''
        })

        const acceptFormats = computed(() => {
            return props.formats?.map(format => `.${format}`).join(',')
        })

        const inputClasses = computed(() => {
            return {
                [styles.input]: true,
                [styles['input-hidden']]: props.status === 'dropped',
            }
        })

        const cardClasses = computed(() => {
            return {
                [styles.card]: true,
                [styles['is-hoverable']]: props.status === 'default',
                [styles['is-dropped']]: props.status === 'dropped',
                [styles['is-dropping']]: isDropping.value,
                [styles['is-error']]: props.isError || errorTextLocal.value,
            }
        })

        return {
            styles,
            isDropping,
            setIsDropping,
            onInput,
            refInput,
            inputClasses,
            cardClasses,
            onDragEnter,
            onDragLeave,
            refContent,
            content,
            errorTextLocal,
            acceptFormats,
        }
    },
    components: {
        'icon-base-component': IconBase,
        'text-component': TheText,
        'transition-fade-component': TransitionFade,
    },
})
</script>

<style module lang="scss" src="./Dropzone.module.scss" />
