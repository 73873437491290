<template lang="pug">
div(
    :class="styles.root"
)
    text-component(
        :class="styles.title"
        name="titleSmallSemibold"
        color="primary"
    ) {{ content.formPay.title }}
    text-component(
        :class="styles.subtitle"
        name="captionRegular"
        color="secondary"
    ) {{ content.formPay.subtitleStart }} {{ store.order.amount }} {{ content.formPay.subtitleEnd }}
    button-component(
        :class="styles['button-faq']"
        size="m"
        view="actionFlat"
        @click="onClickToFaq"
    ) {{ content.formPay.buttonToFaq }}
    card-component(
        :class="styles.card"
    )
    text-component(
        :class="styles['order-id']"
        :isAnimated="false"
        name="captionRegular"
        color="secondary"
    ) {{ content.formPay.orderId }} {{ store.order.id }}
    button-component(
        :class="styles['button-pay']"
        size="l"
        @click="onClickToPay"
    ) {{ content.formPay.buttonPay }}
    text-component(
        :class="styles.timer"
        name="captionRegular"
        color="secondary"
    ) {{ content.formPay.timer }} 
        span.is-bold {{ store.displayTime }}
</template>

<script lang="ts">
import Vue, { useCssModule } from 'vue'
import { storeToRefs } from 'pinia'

import { Step, useLanguage, useStore } from '@/entities/order/model'
import { Card } from '@/entities/order/ui/Layout/FormPay/Card'

import { TheText } from '@/shared/ui/TheText'
import { Button } from '@/shared/ui/Button'

export default Vue.extend({
    name: 'FormPay',
    setup() {
        const styles = useCssModule()
        const storeLanguage = useLanguage()
        const { content } = storeToRefs(storeLanguage)
        const store = useStore()

        function onClickToFaq() {
            store.setStep(Step.FAQ)
        }

        function onClickToPay() {
            store.setStep(Step.VERIFICATION)
        }

        return {
            styles,
            content,
            store,
            onClickToFaq,
            onClickToPay,
        }
    },
    components: {
        'text-component': TheText,
        'button-component': Button,
        'card-component': Card,
    },
})
</script>

<style module lang="scss" src="./FormPay.module.scss" />
