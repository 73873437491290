import { computed, ref } from 'vue'

type TimeS = number & { __tag?: 'TimeS' }

export const useTimer = (callback?: () => void) => {
    const timer = ref(0)
    const displayTime = computed(() => formatTime(timer.value))

    const intervalId = ref<NodeJS.Timeout | null>(null)

    const startTimer = (time: TimeStamp | TimeS) => {
        if (intervalId.value) {
            clearInterval(intervalId.value)
        }

        timer.value = time

        intervalId.value = setInterval(() => {
            timer.value = timer.value - 1

            if (timer.value <= 0 && intervalId.value) {
                clearInterval(intervalId.value)
                callback?.()
            }
        }, 1000)
    }

    const restart = (time: TimeStamp | TimeS) => {
        startTimer(time)
    }

    return { timer, displayTime, restart }
}

const formatTime = (time: TimeS) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    const prefix = seconds < 10 ? '0' : ''

    return `${minutes < 10 ? `0${minutes}` : minutes}:${prefix}${seconds}`
}
